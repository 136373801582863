import fonts from 'google-fonts-complete';
import { isValidCssColor } from 'utils/isValidCssColor';

export const fontFamilies: Record<string, any> = fonts;

export const fontNames: string[] = Object.keys(fonts);

export const getVariants = (selected: string) => {
  if (selected) {
    const selectedVariants = Object.entries(
      fontFamilies[selected]?.variants || {},
    );
    return selectedVariants.reduce((acc, [name, weights]) => {
      const weightObjects = Object.keys(weights as object).map(weight => ({
        variant: name,
        label: weight,
        value: weight,
      }));

      return [...acc, ...weightObjects];
    }, [] as Record<string, any>[]);
  }
  return [];
};

export function validateColorInput(color: string) {
  if (!isValidCssColor(color)) {
    return 'Invalid color value. Only CSS (background) color values are supported';
  }
}

type FontVariants = Record<
  string,
  Record<
    number | string,
    {
      local: string[];
      url: {
        eot: string;
        svg: string;
        ttf: string;
        woff: string;
        woff2: string;
      };
    }
  >
>;
export type Font = {
  category: string;
  lastModified: string;
  subsets: string[];
  unicodeRange: Record<string, string>;
  variants: FontVariants;
  version: string;
};
