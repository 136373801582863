import * as React from 'react';
import { Field } from '@oms/ui-field';
import { NumberInput } from '@oms/ui-number-input';
import { createForm } from './createForm';
import { TRADES } from 'constants/modules';

const [Trades] = createForm(TRADES.key, () => (
  <>
    <Field as={NumberInput} name="numberOfTrades" label="Number of trades" />
  </>
));

export { Trades };
