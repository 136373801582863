export const userAgent = (
  (navigator && navigator.userAgent) ||
  ''
).toLowerCase();

export const ie = userAgent.match(/(?:msie |trident.+?; rv:)(\d+)/);
export const safari = userAgent.match(/version\/(\d+).+?safari/);
export const opera = userAgent.match(/(?:^opera.+?version|opr)\/(\d+)/);
export const ios = userAgent.match(/(ipad|iphone|ipod)/g);
export const supportsBroadcastChannel = !ie && !safari && !opera && !ios;
