import React from 'react';
import { Field, Fieldset } from '@oms/ui-field';
import { Heading } from '@oms/ui-heading';
import { Segment, SegmentGroup } from '@oms/ui-segment';
import { DisclosureSection } from 'components/DisclosureSection';
import { OnChange } from './OnChange';
import { ColorInput } from './ColorInput';
import { validateColorInput } from './utils';

function Table() {
  return (
    <DisclosureSection title="Table">
      <Heading as="h4" variant="heading6">
        Table head
      </Heading>
      <Field
        as={ColorInput}
        name="tableHeadTextColor"
        label="Text color"
        validate={validateColorInput}
      />
      <Field
        as={ColorInput}
        name="tableHeadBackgroundColor"
        label="Background color"
      />
      <Fieldset
        as={SegmentGroup}
        name="tableHeadLetterCase"
        legend="Case style"
      >
        <Field as={Segment} label="Ag" value="none" message="Sentence case" />
        <Field as={Segment} label="AG" value="uppercase" message="All caps" />
      </Fieldset>
      <Fieldset as={SegmentGroup} name="tableHeadBorder" legend="Border">
        <Field as={Segment} label="none" value="none" message="None" />
        <Field as={Segment} label="bottom" value="bottom" message="Bottom" />
        <Field as={Segment} label="top/bottom" value="both" message="both" />
        <Field as={Segment} label="all" value="all" message="all" />
      </Fieldset>
      <Field
        as={ColorInput}
        name="tableHeadBorderColor"
        label="Border color"
        validate={validateColorInput}
      />
      <Heading as="h4" variant="heading6">
        Table rows
      </Heading>
      <Fieldset as={SegmentGroup} name="tableCellBorder" legend="Cell border">
        <Field as={Segment} label="none" value="none" message="None" />
        <Field as={Segment} label="bottom" value="bottom" message="Bottom" />
        <Field as={Segment} label="all" value="all" message="all" />
      </Fieldset>
      <Field
        as={ColorInput}
        name="tableCellBorderColor"
        label="Border color"
        validate={validateColorInput}
      />
      <Fieldset
        as={SegmentGroup}
        name="tableCellBackground"
        legend="Background"
      >
        <Field as={Segment} label="none" value="none" message="None" />
        <Field as={Segment} label="solid" value="solid" message="Solid" />
        <Field as={Segment} label="striped" value="striped" message="Striped" />
      </Fieldset>
      <OnChange name="tableCellBackground">
        {(value: 'none' | 'solid' | 'striped') => {
          if (value === 'solid')
            return (
              <Field
                as={ColorInput}
                name="tableCellBackgroundColor"
                label="Background color"
                validate={validateColorInput}
              />
            );
          if (value === 'striped')
            return (
              <>
                <Field
                  as={ColorInput}
                  name="tableCellBackgroundColor"
                  label="Background color"
                  validate={validateColorInput}
                />
                <Field
                  as={ColorInput}
                  name="tableCellBackgroundColorOdd"
                  label="Odd background color"
                  validate={validateColorInput}
                />
              </>
            );
          return null;
        }}
      </OnChange>
    </DisclosureSection>
  );
}

export { Table };
