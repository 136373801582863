import React from 'react';
import styled from '@xstyled/emotion';

export const Text = styled.p`
  font-size: 12px;
  text-align: center;
`;

export const Link = styled.a`
  display: inline;
  margin-top: 15px;
  font-size: inherit;
  font-style: italic;
  text-align: center;
  color: link;
`;

export default function Disclaimer() {
  const date = new Date();
  let year = date.getFullYear();
  return (
    <>
      <Text>
        Powered by{' '}
        <Link
          href="https://www.infrontfinance.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Infront
        </Link>
      </Text>
      <Text>
        © {year} Euronext N.V. All Rights Reserved. The information, data,
        analysis and Information contained herein include the proprietary
        information of Euronext and its content providers, (ii) may not be
        copied or further disseminated, by any media whatsoever, except as
        specifically authorized by Euronext, (iii) do not constitute investment
        advice, (iv) are provided solely for informational purposes and (v) are
        not warranted to be complete, accurate or timely.
      </Text>
    </>
  );
}

export { Disclaimer };
