import React from 'react';
import { TextInput } from '@oms/ui-text-input';
import { Box } from '@oms/ui-box';

type ColorInputProps = Omit<JSX.IntrinsicElements['input'], 'type'>;

export const ColorInput = React.forwardRef<HTMLInputElement, ColorInputProps>(
  function ColorInput(props, ref) {
    return (
      <TextInput
        ref={ref}
        {...props}
        type="text"
        leftElement={
          <Box
            width="1.25rem"
            height="1.25rem"
            borderRadius="rounded"
            border="sm"
            borderColor="border"
            ml={2}
            style={{
              userSelect: 'none',
              backgroundColor: props.value?.toString(),
            }}
          />
        }
      />
    );
  },
);
