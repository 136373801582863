import React, { useCallback, useEffect, useState } from 'react';
import merge from 'deepmerge';
import { css, Global } from '@xstyled/emotion';
import { transparentize, darken } from 'polished';
import GoogleFontLoader from 'react-google-font-loader';
import { theme } from '@oms/ui-theming';
import { THEME_CHANNEL } from 'components/ThemeBroadcaster';
import { flattenThemeFormValues } from 'pages/Editor';
import { useCustomerByToken } from 'client/customers';
import { supportsBroadcastChannel } from 'utils/userAgent';
import { defaultTheme } from './defaultTheme';

import { ThemeProperty } from 'client/customers-utils';

export function useModuleTheme(token: string) {
  const pick = usePickProperty(token);
  const custom = {
    useCustomProperties: false,
    colors: {
      input: 'transparent',
      text: pick('bodyTextColor'),
      link: pick('linkTextColor'),
      pos: pick('posColor'),
      'text-pos': pick('posColor'),
      neg: pick('negColor'),
      'text-neg': pick('negColor'),
      'chart-1': pick('chartLineColor'),
      'chart-fill-start-1': pick('chartFillTopColor'),
      'chart-fill-end-1': pick('chartFillBottomColor'),
      'border-focus': pick('buttonBorderColor'),
      /** project specific tokens */
      headingTextColor: pick('headingTextColor'),
      tableCellBorderColor: pick('tableCellBorderColor'),
      tableCellBackgroundColorOdd: pick('tableCellBackgroundColorOdd'),
      tableCellBackgroundColor: pick('tableCellBackgroundColor'),
      tableHeadBorderColor: pick('tableHeadBorderColor'),
      tableHeadTextColor: pick('tableHeadTextColor'),
      tableHeadBackgroundColor: pick('tableHeadBackgroundColor'),
      pageBackgroundColor: pick('pageBackgroundColor'),
      border: pick('borderColor'),
      chartTextColor: pick('chartTextColor'),
      chartGridLineColor: pick('chartGridLineColor'),
    },
    fonts: {
      heading: pick('headingFontFamily'),
      body: pick('bodyFontFamily'),
      label: pick('bodyFontFamily'),
      system: defaultTheme.bodyFontFamily,
    },
    shadows: {
      focus: `0 0 0 3px ${transparentize(0.7, pick('buttonBackgroundColor'))}`,
    },
    button: {
      default: {
        borderRadius: pick('buttonBorderRadius'),
      },
      sizes: {
        md: {
          minHeight: '2.5rem',
        },
      },
      variants: {
        primary: {
          color: pick('buttonTextColor'),
          backgroundColor: pick('buttonBackgroundColor'),
          borderColor: pick('buttonBorderColor'),
          borderWidth: 'sm',
          '&:hover': {
            backgroundColor: darken(0.05, pick('buttonBackgroundColor')),
          },
        },
      },
    },
    heading: {
      default: css`
        font-family: heading;
      `,
    },
    table: {
      default: css`
        td,
        th {
          vertical-align: middle;
          line-height: 1.125rem;
        }

        tbody tr[data-selected='true'],
        tbody tr[aria-selected='true'] {
          background-color: selected;
        }

        /** Th color and background color  */
        thead th {
          color: tableHeadTextColor;
          background-color: tableHeadBackgroundColor;
          font-weight: 500;
        }

        /** Th letter case */
        tbody th,
        thead th {
          ${condition(
            pick('tableHeadLetterCase') === 'none',
            css`
              text-transform: initial;
            `,
          )}

          ${condition(
            pick('tableHeadLetterCase') === 'uppercase',
            css`
              text-transform: uppercase;
            `,
          )}
        }
      `,

      variants: {
        normal: css`
          /** Th border */
          thead th {
            ${condition(
              pick('tableHeadBorder') === 'all',
              css`
                border: sm;
                border-color: tableHeadBorderColor;
              `,
            )}
            ${condition(
              pick('tableHeadBorder') === 'both',
              css`
                border-bottom: sm;
                border-top: sm;
                border-color: tableHeadBorderColor;
              `,
            )}
            ${condition(
              pick('tableHeadBorder') === 'bottom',
              css`
                border-bottom: sm;
                border-color: tableHeadBorderColor;
              `,
            )}
          }
          /** Td border */
          tbody th,
          tbody td {
            ${condition(
              pick('tableCellBorder') === 'all',
              css`
                border: sm;
                border-color: tableCellBorderColor;
              `,
            )}

            ${condition(
              pick('tableCellBorder') === 'bottom',
              css`
                border-bottom: sm;
                border-color: tableCellBorderColor;
              `,
            )}
          }

          /** Striping */
          tbody tr td {
            ${condition(
              pick('tableCellBackground') === 'solid',
              css`
                background-color: tableCellBackgroundColor;
              `,
            )}
          }

          tbody tr:nth-of-type(odd) th,
          tbody tr:nth-of-type(odd) td {
            ${condition(
              pick('tableCellBackground') === 'striped',
              css`
                background-color: tableCellBackgroundColorOdd;
              `,
            )}
          }
        `,
        keyValue: css`
        /** Th font */
        thead th,
        tbody th {
          text-transform: uppercase;
          font-size: sm;
          font-weight: bold;
        }
        /** Th border */
        thead th {
            ${condition(
              pick('tableHeadBorder') === 'all',
              css`
                border: sm;
                border-color: tableHeadBorderColor;
              `,
            )}
            ${condition(
              pick('tableHeadBorder') === 'both',
              css`
                border-bottom: sm;
                border-top: sm;
                border-color: tableHeadBorderColor;
              `,
            )}
            ${condition(
              pick('tableHeadBorder') === 'bottom',
              css`
                border-bottom: sm;
                border-color: tableHeadBorderColor;
              `,
            )}
          }
          /** Td border */
          tbody th,
          tbody td {
            ${condition(
              pick('tableCellBorder') === 'all',
              css`
                border: sm;
                border-color: tableCellBorderColor;
              `,
            )}

            ${condition(
              pick('tableCellBorder') === 'bottom',
              css`
                border-bottom: sm;
                border-color: tableCellBorderColor;
              `,
            )}
          }

          /** Striping */
          tbody tr td {
            ${condition(
              pick('tableCellBackground') === 'solid',
              css`
                background-color: tableCellBackgroundColor;
              `,
            )}
          }

          tbody tr:nth-of-type(odd) th,
          tbody tr:nth-of-type(odd) td {
            ${condition(
              pick('tableCellBackground') === 'striped',
              css`
                background-color: tableCellBackgroundColorOdd;
              `,
            )}
          }
        `,
      },
      densities: {
        normal: {
          'td,th': {
            fontSize: 'md',
            padding: '0.455rem',
            paddingBottom: '0.4125rem',
          },
        },
      },
    },
    interactiveList: {
      /**
       * It's recommended to keep these styles similar to `table`
       */
      default: css`
        [data-row-group] {
          line-height: 1.125rem;
          font-size: md;
        }
        [data-row] {
        }
        [data-row]:focus,
        [data-row]:hover {
        }
        [data-row]:active,
        [data-row][aria-checked='true'] {
          color: inverse-text !important;
          background-color: selected;
          [data-trend] {
            color: inverse-text;
          }
        }
        [data-heading-cell],
        [data-cell] {
          padding: 0.675rem;
          padding-bottom: 0.6125rem;
          height: 2.75rem;
        }
        [data-heading-cell] {
          color: tableHeadTextColor;
          background-color: tableHeadBackgroundColor;
          font-weight: 500;
        }
      `,
      variants: {
        primary: css`
          [data-row] {
            [data-heading-cell] {
              ${condition(
                pick('tableHeadBorder') === 'all',
                css`
                  border: sm;
                  border-color: tableHeadBorderColor;
                `,
              )}
              ${condition(
                pick('tableHeadBorder') === 'both',
                css`
                  border-bottom: sm;
                  border-top: sm;
                  border-color: tableHeadBorderColor;
                `,
              )}
              ${condition(
                pick('tableHeadBorder') === 'bottom',
                css`
                  border-bottom: sm;
                  border-color: tableHeadBorderColor;
                `,
              )}
            }
            [data-cell] {
              ${condition(
                pick('tableCellBorder') === 'all',
                css`
                  border: sm;
                  border-color: tableCellBorderColor;
                `,
              )}
              ${condition(
                pick('tableCellBorder') === 'bottom',
                css`
                  border-bottom: sm;
                  border-color: tableCellBorderColor;
                `,
              )}
              ${condition(
                pick('tableCellBackground') === 'solid',
                css`
                  background-color: tableCellBackgroundColor;
                `,
              )}
            }
          }
          [data-row]tr:nth-of-type(odd) [data-cell],
          [data-row]tr:nth-of-type(odd) [data-heading-cell] {
            ${condition(
              pick('tableCellBackground') === 'striped',
              css`
                background-color: tableCellBackgroundColorOdd;
              `,
            )}
          }
        `,
        secondary: '',
        tertiary: '',
      },
    },
  };
  const moduleTheme = merge(theme, custom) as any;

  const hasCustomBodyFont =
    pick('bodyFontFamily') !== defaultTheme.bodyFontFamily;
  const hasCustomHeadingFont =
    pick('headingFontFamily') !== defaultTheme.headingFontFamily;
  const hasCustomFont = hasCustomBodyFont || hasCustomHeadingFont;

  const GlobalStyles = useCallback(
    () => (
      <>
        {hasCustomFont && (
          <GoogleFontLoader
            fonts={[
              { font: pick('bodyFontFamily'), weights: [400] },
              { font: pick('headingFontFamily'), weights: [400] },
            ]}
          />
        )}
        <Global
          styles={css`
            html {
              background-color: transparent;
            }
            body {
              color: ${pick('bodyTextColor')};
              background-color: ${pick('pageBackgroundColor')};
              font-family: ${pick('bodyFontFamily')};
              *:focus {
                outline: none !important;
              }
              .highcharts-background {
                fill: ${pick('pageBackgroundColor')};
              }
              .AnalysisChart--expandButton {
                border: none;
                background: none;
                width: 100%;
                cursor: pointer;
              }
            }
            a {
              color: ${pick('linkTextColor')} !important;
            }
          `}
        />
      </>
    ),
    [pick, hasCustomFont],
  );

  return { theme: moduleTheme, GlobalStyles };
}

function usePickProperty(token: string) {
  const [liveProperties, setLiveProperties] = useState<
    Array<ThemeProperty> | undefined
  >(undefined);
  const properties = useCustomerByToken(token).properties;

  useEffect(() => {
    if (supportsBroadcastChannel) {
      const tbc = new BroadcastChannel(THEME_CHANNEL);
      const handler = (event: MessageEvent<any>) => {
        if (event.data.token === token) {
          const update = Object.entries(
            flattenThemeFormValues(event.data.theme),
          ).map(([key, value]) => ({
            key,
            value,
          }));

          setLiveProperties((update as unknown) as Array<ThemeProperty>);
        }
      };
      tbc.addEventListener('message', handler);
      return () => {
        tbc.removeEventListener('message', handler);
      };
    }
  }, [token]);

  const pick = useCallback(
    (key: keyof typeof defaultTheme, fallback?: string) => {
      const liveProp = liveProperties?.find(property => property.key === key)
        ?.value;
      const prop = properties?.find(property => property.key === key)?.value;
      const value = (liveProp ||
        prop ||
        fallback ||
        defaultTheme[key]) as string;
      // remove accidental white spaces
      return typeof value === 'string' ? value.trim() : value;
    },
    [properties, liveProperties],
  );

  return pick;
}

function condition(cond: boolean, rule: any) {
  return cond ? (typeof rule === 'function' ? rule({}) : rule) : undefined;
}
