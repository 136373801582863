import React from 'react';
import { Field, Fieldset } from '@oms/ui-field';
import { Segment, SegmentGroup } from '@oms/ui-segment';
import { DisclosureSection } from 'components/DisclosureSection';
import { ColorInput } from './ColorInput';
import { validateColorInput } from './utils';

function Button() {
  return (
    <DisclosureSection title="Button">
      <Field
        as={ColorInput}
        name="buttonBackgroundColor"
        label="Background color"
        validate={validateColorInput}
      />
      <Field
        as={ColorInput}
        name="buttonTextColor"
        label="Text color"
        validate={validateColorInput}
      />
      <Field
        as={ColorInput}
        name="buttonBorderColor"
        label="Border color"
        validate={validateColorInput}
      />
      <Fieldset
        as={SegmentGroup}
        name="buttonBorderRadius"
        legend="Corner radius"
      >
        <Field as={Segment} label="none" value="none" />
        <Field as={Segment} label="sm" value="sm" />
        <Field as={Segment} label="md" value="md" />
        <Field as={Segment} label="rounded" value="rounded" />
      </Fieldset>
    </DisclosureSection>
  );
}

export { Button };
