import React, { Suspense } from 'react';
import { ErrorBoundary } from '@oms/ui-error-boundary';
import { Box } from '@oms/ui-box';
import { ModuleFallback } from 'components/ModuleFallback';
import { I18nProvider } from 'components/I18nProvider';
import { Guard } from 'components/Guard';
import { CustomerProvider } from 'client/customers';
import { OMSProvider } from './OMSProvider';

function ModuleProvider({
  irModule,
  token,
  lang = 'en',
  children,
}: ModuleProviderProps) {
  return (
    <ErrorBoundary>
      <Suspense fallback={<ModuleFallback irModule={irModule} />}>
        <CustomerProvider token={token}>
          <OMSProvider lang={lang} token={token}>
            <I18nProvider language={lang}>
              {irModule === '__TEST__' ? (
                <Box display="flex" id="modules">{children}</Box>
              ) : (
                <Guard token={token} irModule={irModule}>
                  <Box display="flex" id="modules">{children}</Box>
                </Guard>
              )}
            </I18nProvider>
          </OMSProvider>
        </CustomerProvider>
      </Suspense>
    </ErrorBoundary>
  );
}

interface ModuleProviderProps {
  irModule: string;
  token: string;
  lang?: string;
  children: React.ReactNode;
}

export { ModuleProvider };
