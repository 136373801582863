import * as React from 'react';
import { Field } from '@oms/ui-field';
import { NumberInput } from '@oms/ui-number-input';
import { TextInput } from '@oms/ui-text-input';
import { createForm } from './createForm';
import { COMPANY_DISCLOSURES } from 'constants/modules';

const [CompanyDisclosure] = createForm(COMPANY_DISCLOSURES.key, () => (
  <>
    <Field
      as={NumberInput}
      name="numberOfDisclosures"
      label="Number of disclosures"
    />
    <Field as={NumberInput} name="pageSize" label="Page size" />
    <Field as={TextInput} name="languageFilter" label="Language filter" />
    <Field as={TextInput} name="sourceFilter" label="Source filter" />
  </>
));

export { CompanyDisclosure };
