import { useMemo } from 'react';
import qs from 'querystringify';

export const getSearchParams = <T extends Record<string, any>>(
  location: Location,
) => (qs.parse(location.search) as unknown) as T;

export type UseSearchParamsReturnState = {
  token: string;
  lang?: string;
  ticker?: string;
};

export const useSearchParams = (location: Location = window.location) => {
  return useMemo(
    () => (qs.parse(location.search) as unknown) as UseSearchParamsReturnState,
    [location.search],
  );
};
