import * as React from 'react';
import { Field } from '@oms/ui-field';
import { NumberInput } from '@oms/ui-number-input';
import { createForm } from './createForm';
import { SHAREHOLDERS } from 'constants/modules';

const [Shareholders] = createForm(SHAREHOLDERS.key, () => (
  <>
    <Field as={NumberInput} name="count" label="Count" />
  </>
));

export type ShareholdersFormValues = {
  count: number;
};

export { Shareholders };
