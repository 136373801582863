import * as React from 'react';
import { Field } from '@oms/ui-field';
import { TextInput } from '@oms/ui-text-input';
import { createForm } from './createForm';
import { MINI_GRAPH_INTERACTIVE } from 'constants/modules';

const [MiniGraphInteractive] = createForm(MINI_GRAPH_INTERACTIVE.key, () => (
  <>
    <Field as={TextInput} name="graphLineColor" label="Line color" />
    <Field as={TextInput} name="graphFillStartColor" label="Fill color start" />
    <Field as={TextInput} name="graphFillStopColor" label="Fill color stop" />
    <Field as={TextInput} name="columnColor" label="Column color" />
  </>
));

export type MiniGraphInteractiveFormValues = {
  graphFillStartColor: string;
  graphFillStopColor: string;
  columnColor: string;
};

export { MiniGraphInteractive };
