import React, { useState } from 'react';
import {
  faDesktopAlt,
  faTablet,
  faMobile,
} from '@fortawesome/pro-light-svg-icons';
import styled from '@xstyled/emotion';
import { Icon } from '@oms/ui-icon';
import { Box } from '@oms/ui-box';
import { Tooltip } from '@oms/ui-tooltip';

const IconButton = styled.button`
  appearance: none;
  padding: 2 3;
  border: none;
  background-color: surface-2;
  border-radius: sm;
  transition: all 200ms ease;
  svg {
    fill: text;
  }
  &:hover,
  &:focus {
    background-color: surface-3;
    svg {
      fill: white;
    }
  }
  &[aria-pressed='true'] {
    background-color: surface-1;
    svg {
      fill: selected;
    }
  }
`;

const dimensions: Record<
  Sizes,
  {
    maxWidth: string;
    height?: string | undefined;
    maxHeight?: string | undefined;
  }
> = {
  sm: { maxWidth: '24em', maxHeight: '50.75em' },
  md: { maxWidth: '48em', maxHeight: '64em' },
  lg: { maxWidth: '100%', height: undefined },
};

function useScreenSizeSwitcher(initialState: Sizes = 'lg') {
  const [size, setSize] = useState<Sizes>(initialState);
  return { size, setSize, dimensions: dimensions[size] };
}

function EditorScreenSizeSwitcher({ state }: EditorScreenSizeSwitcherProps) {
  const onClick = (event: ButtonEvent<Sizes>) => {
    const value = event.target?.value;
    state.setSize(value);
  };
  return (
    <Box
      p={1}
      bg="surface-2"
      width="auto"
      display="inline-flex"
      borderRadius="md"
    >
      <Tooltip content="Mobile">
        <IconButton
          value="sm"
          onClick={onClick}
          aria-pressed={state.size === 'sm'}
        >
          <Icon icon={faMobile as any} />
        </IconButton>
      </Tooltip>
      <Tooltip content="Tablet">
        <IconButton
          value="md"
          onClick={onClick}
          aria-pressed={state.size === 'md'}
        >
          <Icon icon={faTablet as any} />
        </IconButton>
      </Tooltip>

      <Tooltip content="Desktop">
        <IconButton
          value="lg"
          onClick={onClick}
          aria-pressed={state.size === 'lg'}
        >
          <Icon icon={faDesktopAlt as any} />
        </IconButton>
      </Tooltip>
    </Box>
  );
}

type Sizes = 'sm' | 'md' | 'lg';

interface UseScreenSizeSwitcherStateReturn
  extends ReturnType<typeof useScreenSizeSwitcher> {}

type ButtonEvent<T extends any = ''> = React.MouseEvent<
  HTMLButtonElement,
  MouseEvent
> & {
  target: HTMLButtonElement;
} & { target: { value: T } };

export interface EditorScreenSizeSwitcherProps {
  state: UseScreenSizeSwitcherStateReturn;
}

export { EditorScreenSizeSwitcher, useScreenSizeSwitcher };
