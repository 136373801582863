import * as React from 'react';
import { SettingsForm as Form } from 'components/SettingsForm';
import { useHandleSubmit, useInitialValues } from './utils';
import { modules } from 'constants/modules';

function createForm(irModule: string, Fields: () => JSX.Element) {
  const FormComponent = ({ customerId }: { customerId: number }) => {
    const details = modules.query({ where: { key: irModule } });

    const submit = useHandleSubmit({
      customerId,
      irModule,
    });
    const initialValues = useInitialValues({
      customerId,
      irModule,
    });

    return (
      <Form
        headerText={details?.label}
        onSubmit={submit}
        initialValues={initialValues}
      >
        <Fields />
      </Form>
    );
  };
  return [FormComponent, Fields] as const;
}

export { createForm };
