import React from 'react';
import { Box } from '@oms/ui-box';
import { StatusIcon } from '@oms/ui-icon';
import { Text } from '@oms/ui-text';

function UnsavedChangesNotification({
  pristine,
  buttonElement,
}: UnsavedChangesNotificationProps) {
  return pristine ? null : (
    <Box display="flex" bg="information" p={4}>
      <StatusIcon status="info" color="inverse-text" />
      <Box flex={1} ml={2}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Text variant="heading6" color="inverse-text">
            You have unsaved changes
          </Text>
          {buttonElement}
        </Box>
      </Box>
    </Box>
  );
}

export interface UnsavedChangesNotificationProps {
  pristine: boolean;
  buttonElement?: JSX.Element;
}

export { UnsavedChangesNotification };
