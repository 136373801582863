import React from 'react';
import { Box, BoxProps } from '@oms/ui-box';
import { Heading, HeadingProps } from '@oms/ui-heading';
import { VisuallyHidden } from '@oms/ui-visually-hidden';

function ModuleSection({ children, ...props }: ModuleSectionProps) {
  return (
    <Box flex={1} {...props}>
      {children}
    </Box>
  );
}

function ModuleHeading({
  hidden,
  children,
  ...styleProps
}: ModuleHeadingProps) {
  if (hidden) {
    return <VisuallyHidden>{children}</VisuallyHidden>;
  }
  return (
    <Heading
      as="h2"
      variant="heading5"
      mb={3}
      /** project specific token */
      color={'headingTextColor' as any}
      {...styleProps}
    >
      {children}
    </Heading>
  );
}

export { ModuleSection, ModuleHeading };

type ModuleSectionProps = Pick<
  BoxProps,
  'children' | 'mr' | 'ml' | 'mt' | 'mx' | 'mb'
>;

type ModuleHeadingProps = Pick<HeadingProps, 'children' | 'mr'> & {
  hidden?: boolean;
};

export type SharedModuleProps = {
  token: string;
  lang: string;
  moduleId: string;
  standalone?: boolean;
  ticker: string;
};

export type ModuleProps<
  T extends keyof SharedModuleProps = 'token' | 'standalone'
> = Pick<SharedModuleProps, T> & Omit<ModuleSectionProps, 'children'>;
