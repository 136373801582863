import * as React from 'react';
import { Field } from '@oms/ui-field';
import { NumberInput } from '@oms/ui-number-input';
import { createForm } from './createForm';
import { ORDER_DEPTH } from 'constants/modules';

const [OrderDepth] = createForm(ORDER_DEPTH.key, () => (
  <>
    <Field as={NumberInput} name="depth" label="Depth" />
  </>
));

export type OrderDepthFormValues = {
  depth: number;
};

export { OrderDepth };
