import React from 'react';
import { Switch, RouteComponentProps } from 'react-router-dom';
import { useInitializeSheet, SheetProvider } from '@oms/ui-sheet';
import { ErrorBoundary } from '@oms/ui-error-boundary';
import { AdminProvider } from 'components/AdminProvider';
import { Route } from 'components/Route';

const Login = React.lazy(() => import('pages/Login'));
const ForgotPassword = React.lazy(() => import('pages/ForgotPassword'));
const NewPassword = React.lazy(() => import('pages/ResetPassword'));
const SignUp = React.lazy(() => import('pages/SignUp'));
const Dashboard = React.lazy(() => import('pages/Dashboard'));
const Customers = React.lazy(() => import('pages/Customers'));
const Customer = React.lazy(() => import('pages/Customer'));
const Editor = React.lazy(() => import('pages/Editor'));
const Admins = React.lazy(() => import('pages/Admins'));
const Products = React.lazy(() => import('pages/Products'));
const NotFoundPage = React.lazy(() => import('pages/NotFoundPage'));

function AdminRoutes(_props: AdminRoutesProps) {
  const editor = useInitializeSheet();

  return (
    <AdminProvider>
      <Switch>
        <Route path="/admin/login" element={<Login />} />
        <Route path="/admin/forgot-password" element={<ForgotPassword />} />
        <Route path="/admin/reset-password/:token" element={<NewPassword />} />
        <Route path="/admin/sign-up" element={<SignUp />} />
        <Route path="/admin/dashboard" element={<Dashboard />} />
        <Route path="/admin/customers" exact element={<Customers />} />
        <Route
          path="/admin/customers/:customerId/editor"
          element={
            <SheetProvider sheets={{ editor }}>
              <Editor />
            </SheetProvider>
          }
        />
        <Route
          path="/admin/customers/:customerId"
          element={
            <ErrorBoundary>
              <Customer />
            </ErrorBoundary>
          }
        />
        <Route path="/admin/admins" element={<Admins />} />
        <Route path="/admin/products" element={<Products />} />
        <Route path="*" element={<NotFoundPage />} />
      </Switch>
    </AdminProvider>
  );
}

interface AdminRoutesProps extends RouteComponentProps<{}> {}

export type MatchParams = {
  customers: { customerId: string };
  'reset-password': { token: string };
  public: { token: string };
};

export { AdminRoutes };
