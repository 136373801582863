import React from 'react';
import { useLocation } from 'react-router-dom';
import { AppShell, AppFooter } from '@oms/ui-app-shell';
import { Box } from '@oms/ui-box';
import { Spinner } from '@oms/ui-spinner';
import { AppNavigationBar } from 'components/AppNavigationBar';
import { ScrollToTopButton } from 'components/ScrollToTopButton';

type AdminProviderProps = {
  children: React.ReactNode;
};

function AdminProvider({ children }: AdminProviderProps) {
  const { pathname } = useLocation();
  const isEditor = RegExp(/editor/).test(pathname);
  return (
    <AppShell
      navigationBar={<AppNavigationBar />}
      footer={
        isEditor ? (
          undefined
        ) : (
          <>
            <ScrollToTopButton />
            <AppFooter />
          </>
        )
      }
    >
      <React.Suspense
        fallback={
          <Box center minHeight="90vh">
            <Spinner size="lg" />
          </Box>
        }
      >
        {children}
      </React.Suspense>
    </AppShell>
  );
}

export { AdminProvider };
