import React from 'react';
import { Box } from '@oms/ui-box';
import { Skeleton } from '@oms/ui-skeleton';
import { ModuleSection, ModuleHeading, ModuleProps } from 'components/Module';

export default function ProfitCalculatorSkeleton({
  ...styleProps
}: ModuleProps) {
  return (
    <ModuleSection {...styleProps}>
      <ModuleHeading>
        <Skeleton height="lg" width="10rem" />
      </ModuleHeading>
      <Box minHeight="10rem" width="100%" />
    </ModuleSection>
  );
}

export { ProfitCalculatorSkeleton };
