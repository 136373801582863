import React from 'react';
import { Skeleton, SkeletonTable } from '@oms/ui-skeleton';
import { ModuleHeading, ModuleSection } from '../Module';

export default function CompanyInfoSkeleton(props: any) {
  return (
    <ModuleSection {...props}>
      <ModuleHeading>
        <Skeleton height="1rem" width="10rem" animated />
      </ModuleHeading>
      <SkeletonTable columns={2} rows={5} mt={3} animated />
    </ModuleSection>
  );
}

export { CompanyInfoSkeleton };
