export const defaultTheme: ModuleTheme = {
  // HEADING
  headingFontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial, sans-serif`,
  headingFontWeight: 400,
  headingFontVariant: 'normal',
  headingTextColor: '#252631',
  // BODY
  bodyFontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial, sans-serif`,
  bodyFontWeight: 400,
  bodyFontVariant: 'normal',
  bodyTextColor: '#252631',
  linkTextColor: '#006adb',
  // TABLE HEAD
  tableHeadTextColor: '#252631',
  tableHeadBackgroundColor: 'transparent',
  tableHeadLetterCase: 'none',
  tableHeadBorder: 'both',
  tableHeadBorderColor: '#dfdfdf',
  // TABLE BODY/CELL
  tableCellBorder: 'bottom',
  tableCellBorderColor: '#dfdfdf',
  tableCellBackground: 'none',
  tableCellBackgroundColor: 'transparent',
  tableCellBackgroundColorOdd: 'transparent',
  // BUTTON
  buttonBackgroundColor: '#006adb',
  buttonTextColor: '#FFFFFF',
  buttonBorderColor: '#006adb',
  buttonBorderRadius: 'sm',
  // CHART
  chartLineColor: '#006adb',
  chartFill: 'none',
  chartFillTopColor: 'transparent',
  chartFillBottomColor: 'transparent',
  chartTextColor: '#666666',
  chartGridLineColor: '#e6e6e6',
  // TREND
  posColor: '#008031',
  negColor: '#e61437',
  // PAGE
  pageBackgroundColor: 'transparent',
  borderColor: '#dfdfdf',
};

type Border = 'none' | 'bottom' | 'both' | 'all';
export type FontStyle = {
  variant?: string;
  label?: string | number;
  value?: string | number;
};
type LetterCase = 'none' | 'uppercase';
type Striping = 'none' | 'solid' | 'striped';
type BorderRadius = 'none' | 'sm' | 'md' | 'rounded';
type ChartFill = 'none' | 'solid' | 'gradient';

export interface ModuleTheme {
  // HEADING
  headingFontFamily: string;
  headingTextColor: string;
  headingFontVariant: string;
  headingFontWeight: string | number;
  // BODY
  bodyFontFamily: string;
  bodyTextColor: string;
  bodyFontVariant: string;
  bodyFontWeight: string | number;
  linkTextColor: string;
  //
  tableHeadTextColor: string;
  tableHeadBackgroundColor: string;
  tableHeadLetterCase: LetterCase;
  tableHeadBorder: Border;
  tableHeadBorderColor: string;
  // TABLE CELL
  tableCellBorder: Border;
  tableCellBorderColor: string;
  tableCellBackground: Striping;
  tableCellBackgroundColor: string;
  tableCellBackgroundColorOdd: string;
  // BUTTON
  buttonBackgroundColor: string;
  buttonTextColor: string;
  buttonBorderColor: string;
  buttonBorderRadius: BorderRadius;
  // CHART
  chartLineColor: string;
  chartFill: ChartFill;
  chartFillTopColor: string;
  chartFillBottomColor: string;
  chartTextColor: string;
  chartGridLineColor: string;
  // TREND
  posColor: string;
  negColor: string;
  // Page
  pageBackgroundColor?: string;
  borderColor: string;
}
