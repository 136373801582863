import { css } from '@xstyled/emotion';
import { theme as defaultTheme } from '@oms/ui-theming';

const noDefault = {};

export const colors = {
  primary: '#009688',
  secondary: '#076e8d',
  tertiary: '#505c6e',
  danger: '#dc143c',
  buy: '#009688',
  sell: '#dc143c',

  'surface-1': '#fff',
  'surface-2': '#f8f8f8',
  'surface-3': '#076e8d',
  'surface-4': 'rgb(38, 35, 66)',
  'surface-5': '#ebf6f5',
  'surface-6': '#252631',
  'surface-7': '#f8e71c',
  'surface-8': '#009639',
  'surface-hover': '#f2f4f6',

  border: '#ccc',
  'contrast-border': '#979797',
  'border-focus': '#80bdff',
  'border-error': '#dc143c',
  'border-disabled': '#dfdfdf',
  'inverse-border': '#fff',

  text: 'rgb(24, 23, 42)',
  'text-label': '#495057',
  'text-placeholder': '#495057',
  'text-message': '#495057',
  'text-disabled': '#495057',
  'text-error': '#dc143c',
  'inverse-text': '#fff',

  link: '#076e8d',

  input: '#fff',
  'input-focus': '#F8FAFB',
  disabled: '#dfdfdf',

  'icon-primary': '#009688',
  'icon-secondary': '#076e8d',
  'icon-hover': '#009688',
  'icon-focus': '#009688',
  'icon-active': '#009688',

  pos: '#009688',
  'text-pos': '#009688',
  neg: '#dc143c',
  'text-neg': '#dc143c',
  flat: '#f1c21b',
  'flat-text': '#f1c21b',
  'flat-map': '#fff',
  blend: '#fff',
  /** Not a color, but a value used to blend colors */
  blendTreshold: 1,

  backdrop: '#00374f24',
  black: '#000',
  white: '#fff',
  current: 'currentColor',
  transparent: 'hsla(0,0%,0%,0)',
  highlight: '#076e8d',

  success: '#009688',
  warning: '#f1c21b',
  error: '#dc143c',
  information: '#076e8d',
  question: '#076e8d',

  'success-hover': '#009688',
  'warning-hover': '#f1c21b',
  'error-hover': '#dc143c',
  'information-hover': '#076e8d',
  'questions-hover': '#076e8d',

  'inverse-success': '#009688',
  'inverse-warning': '#f1c21b',
  'inverse-error': '#dc143c',
  'inverse-information': '#2cc4f3',
  'inverse-question': '#2cc4f3',

  selected: '#076e8d',
  'selected-hover': '#076e8d',

  skeleton: '#f2f4f6',
  'text-skeleton': '#778ca2',
  'skeleton-base': '#dfdfdf',
  'skeleton-highlight': '#d0d0d0',

  'accent-1': '',
  'accent-2': '',
  'accent-3': '',
  'accent-4': '',
  'accent-5': '',

  'chart-1': '',
  'chart-2': '',
  'chart-3': '',
  'chart-4': '',
  'chart-5': '',
  'chart-fill-start-1': '',
  'chart-fill-start-2': '',
  'chart-fill-start-3': '',
  'chart-fill-start-4': '',
  'chart-fill-start-5': '',
  'chart-fill-end-1': '',
  'chart-fill-end-2': '',
  'chart-fill-end-3': '',
  'chart-fill-end-4': '',
  'chart-fill-end-5': '',

  'scroll-thumb': 'rgba(0, 0, 0, 0.3)',
  'scroll-track': 'rgba(0, 0, 0, 0.1)',
  'scroll-thumb-idle': 'rgba(0, 0, 0, 0.0)',
  'scroll-track-idle': 'rgba(0, 0, 0, 0.0)',
  'scroll-fade-in': 'rgba(255, 255, 255,1)',
  'scroll-fade-out': 'rgba(255, 255, 255,0)',
  modes: {
    dark: {},
  },
};

export const shadows = {
  focus: '0 0 0 0.2rem rgb(0 123 255 / 25%)',
  'focus-error': '0 0 0 3px #dc143c77',
  'focus-inset': 'inset 0 0 0 3px rgb(0 123 255 / 25%)',
  menu: '0 1px 2px 0 rgba(60,64,67,.3), 0 2px 6px 2px rgba(60,64,67,.15)',
};

export const googleFonts = [
  {
    font: 'Roboto',
    weights: [400, '400i'],
  },
];

export const fonts = {
  heading: `Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`,
  body: `Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`,
  label: `Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`,
};

/**
 * Component style config
 * @memberof theming
 */
export const components = {
  accordion: {
    headerHeight: '3.5rem',
    header: noDefault,
    content: noDefault,
  },

  appShell: {
    header: noDefault,
    main: noDefault,
    status: {
      info: {
        color: 'inverse-text',
        backgroundColor: 'inverse-info',
      },
      success: {
        color: 'inverse-text',
        backgroundColor: 'inverse-success',
      },
      warning: {
        color: 'inverse-text',
        backgroundColor: 'inverse-warning',
      },
      error: {
        color: 'inverse-text',
        backgroundColor: 'inverse-error',
      },
      question: {
        color: 'inverse-text',
        backgroundColor: 'inverse-question',
      },
    },
  },
  backdrop: noDefault,
  badge: {
    default: noDefault,
    variants: {
      primary: noDefault,
      secondary: noDefault,
      tertiary: noDefault,
    },
  },
  breadcrumb: {
    default: noDefault,
    item: noDefault,
  },
  button: {
    default: css`
      border-radius: rounded;
      &[aria-disabled='true'] {
        color: #5c5c5c;
      }
    `,
    sizes: {
      xs: css`
        padding-top: 0.1875rem;
        padding-bottom: 0.1875rem;
      `,
      sm: css`
        padding-top: 0.4375rem;
        padding-bottom: 0.4375rem;
      `,
      md: css`
        padding-top: 0.875rem;
        padding-bottom: 0.875rem;
      `,
      lg: css`
        padding-top: 0.8125rem;
        padding-bottom: 0.8125rem;
      `,
      xl: css`
        padding-top: 1.3125rem;
        padding-bottom: 1.3125rem;
      `,
    },
    variants: {
      primary: css`
        color: inverse-text;
        background-color: primary;
        border: sm;
        border-color: primary;
        box-shadow: 0 4px 14px rgba(0, 0, 0, 0.04),
          0 4px 12px rgba(0, 0, 0, 0.04);
        &:hover {
          color: primary;
          background-color: transparent;
        }
        &:active {
          color: primary;
          background-color: transparent;
        }
      `,
      secondary: css`
        color: primary;
        border: sm;
        border-color: primary;
        &:hover {
          color: inverse-text;
          background-color: primary;
          border-color: primary;
        }
        &:active {
          color: inverse-text;
          background-color: primary;
          border-color: primary;
        }
      `,
      tertiary: css`
        color: text;
        background-color: #ebedf0;
        &:hover {
          background-color: #ebf6f5;
        }
        &:active {
          background-color: #ebf6f5;
        }
      `,
      link: css`
        padding: 0;
        color: link;
        &:hover {
          text-decoration: underline;
        }
        &:focus {
          text-decoration: underline;
        }
        &:active {
        }
      `,
      buy: css`
        color: text;
        background-color: buy;
        &:hover {
          background-color: buy-hover;
        }
        &:focus {
          background-color: buy-focus;
        }
        &:active {
          background-color: buy-active;
        }
      `,
      sell: css`
        color: text;
        background-color: sell;
        &:hover {
          background-color: sell-hover;
        }
        &:focus {
          background-color: sell-focus;
        }
        &:active {
          background-color: sell-active;
        }
      `,
      danger: css`
        color: text;
        background-color: danger;
        &:hover {
          background-color: danger-hover;
        }
        &:focus {
          background-color: danger-focus;
        }
        &:active {
          background-color: danger-active;
        }
      `,
      inherit: css`
        all: inherit;
      `,
      plain: noDefault,
    },
  },
  card: {
    default: css`
      padding: 4;
      box-shadow: md;
      border-radius: md;
    `,
    actions: noDefault,
  },
  checkbox: {
    default: css`
      border: sm;
      border-color: selected;
      border-radius: md;

      :disabled {
        border-color: disabled;
      }
    `,
    checked: css`
      border-color: selected;
      & + [role='presentation'] {
        border-radius: md;
        background-color: selected;
        &::after {
          /*
      * The checkmark is made off a border
      */
          border-color: white;
        }
      }
    `,
    sizes: {
      md: css`
        width: 1rem;
        height: 1rem;
      `,
      lg: css`
        width: 1.5rem;
        height: 1.5rem;
      `,
    },
  },
  chip: {
    default: {
      borderRadius: 'rounded',
    },
    sizes: {
      sm: css`
        height: 1.25rem;
      `,
      md: css`
        height: 1.75rem;
      `,
      lg: css`
        height: 2rem;
      `,
    },
    variants: {
      primary: css`
        background-color: surface-hover;
        border: sm;
        border-color: surface-hover;
      `,
      secondary: css`
        border: sm;
        border-color: primary;
      `,
      tertiary: noDefault,
    },
  },
  choice: {
    default: css`
      border-radius: none;
      border: sm;
      border-color: border;
    `,
    checked: css`
      &[data-orientation='horizontal'] {
        border-left: lg;
        border-left-color: primary;
      }
      &[data-orientation='vertical'] {
        border-bottom: lg;
        border-bottom-color: primary;
      }
    `,
    hover: noDefault,
    'focus-within': noDefault,
  },
  combobox: {
    default: noDefault,
    button: {
      default: noDefault,
      open: css`
        svg {
          transform: rotate(180deg);
        }
      `,
    },
  },
  contentSwitcher: {
    default: css`
      li {
        padding: 0.675rem;
        padding-bottom: 0.6125rem;
        min-width: 3rem;
      }
      li:hover {
        background-color: surface-hover;
      }
      &[aria-orientation='horizontal'] li[aria-checked='true'] {
        font-weight: medium;
        border-bottom: md;
        border-color: selected;
      }
      &[aria-orientation='vertical'] li[aria-checked='true'] {
        font-weight: medium;
        border-right: md;
        border-color: selected;
      }
    `,
    variants: {
      primary: css``,
    },
  },
  dateInput: {
    default: noDefault,
    day: {
      default: css`
        &:hover {
          color: icon-1;
          background-color: icon-hover;
          border-radius: rounded;
        }
      `,
      today: noDefault,
      selected: css`
        border-radius: rounded;
      `,
      weekday: noDefault,
      weekend: noDefault,
      blocked: noDefault,
    },
  },
  descriptionList: {
    default: noDefault,
    term: noDefault,
    definition: noDefault,
  },
  dialog: {
    default: css`
      border: sm;
      border-color: border;
      border-radius: none;
      box-shadow: lg;
    `,
    header: noDefault,
    content: noDefault,
    actions: noDefault,
  },
  drawer: {
    default: css`
      box-shadow: lg;
    `,
    header: noDefault,
    content: noDefault,
    actions: noDefault,
    placement: {
      top: css`
        border-bottom-left-radius: lg;
        border-bottom-right-radius: lg;
      `,
      right: noDefault,
      bottom: css`
        border-top-left-radius: lg;
        border-top-right-radius: lg;
      `,
      left: noDefault,
    },
  },
  field: {
    label: css`
      font-size: md;
    `,
    message: css`
      font-size: sm;
    `,
    error: css`
      font-size: sm;
    `,
  },
  formError: {
    default: noDefault,
    item: noDefault,
  },
  heading: {
    default: noDefault,
    variants: {
      heading1: css`
        font-size: 4xl;
        font-weight: light;
        line-height: 1.25;
      `,
      heading2: css`
        font-size: 3xl;
        font-weight: light;
        line-height: 1.25;
      `,
      heading3: css`
        font-size: 2xl;
        font-weight: light;
        line-height: 1.25;
      `,
      heading4: css`
        font-size: xl;
        font-weight: normal;
        line-height: 1.25;
      `,
      heading5: css`
        font-size: lg;
        font-weight: bold;
        line-height: 1.25;
      `,
      heading6: css`
        font-size: md;
        font-weight: bold;
        line-height: 1.25;
      `,
      body1: css`
        font-size: sm;
        line-height: 1rem;
      `,
      body2: css`
        font-size: md;
        line-height: 1.125rem;
      `,
      body3: css`
        font-size: lg;
        line-height: 1.5rem;
      `,
      subtitle1: css`
        font-size: md;
        line-height: 1.125rem;
      `,
      subtitle2: css`
        font-size: lg;
        line-height: 1.5rem;
      `,
      button: css`
        font-size: md;
        line-height: 1.125rem;
      `,
      caption: noDefault,
      overline: noDefault,
      label: noDefault,
      message: noDefault,
      error: noDefault,
    },
  },
  icon: {
    default: noDefault,
    variants: {
      primary: css`
        color: text;
      `,
      secondary: css`
        color: primary;
      `,
      tertiary: noDefault,
    },
  },
  iconButton: {
    default: noDefault,
    variants: {
      primary: css`
        border-radius: rounded;
        background-color: transparent;
        svg {
          fill: icon-primary;
        }
        &:focus {
          background-color: icon-focus;
          svg {
            fill: inverse-text !important;
          }
        }
        &:hover {
          background-color: icon-hover;
          svg {
            fill: inverse-text !important;
          }
        }
        &:active {
          background-color: icon-active;
          svg {
            fill: inverse-text !important;
          }
        }
      `,
      secondary: css`
        /**
        * Secondary icons are mend to be used as part of a larger 
        * component, e.g. DateInput, Combobox.
        * The icon buttons usually lack expressive focus styles.
        */
        color: secondary;
        svg,
        &::after {
          color: secondary;
        }
        &:hover::before,
        &:hover::after {
          background-color: surface-hover;
        }
      `,
      tertiary: noDefault,
    },
    sizes: {
      sm: css`
        height: 1.75rem;
        width: 1.75rem;
      `,
      md: css`
        height: 2rem;
        width: 2rem;
      `,
      lg: css`
        height: 2.5rem;
        width: 2.5rem;
      `,
    },
  },
  interactiveList: {
    /**
     * It's recommended to keep these styles similar to `table`
     */
    default: css`
      [data-row-group] {
        line-height: 1.125rem;
        font-size: md;
      }
      [data-row] {
      }
      [data-row]:focus,
      [data-row]:hover {
        background-color: surface-hover;
      }
      [data-row]:active,
      [data-row][aria-checked='true'] {
        color: inverse-text !important;
        background-color: selected;
      }
      [data-heading-cell],
      [data-cell] {
        padding: 0.675rem;
        padding-bottom: 0.6125rem;
        height: 2.75rem;
      }
    `,
    variants: {
      primary: css`
        [data-row] {
          [data-heading-cell] {
            font-weight: medium;
            background-color: surface-1;
            border-top: sm;
            border-bottom: sm;
            border-color: border;
          }
          [data-cell] {
            border-top: sm;
            border-bottom: sm;
            border-color: border;
          }
        }
      `,
      secondary: noDefault,
      tertiary: noDefault,
    },
  },
  loading: {
    /** dot background-color */
    backgroundColor: 'primary',
  },
  menu: {
    default: noDefault,
    item: {
      default: noDefault,
      highlighted: css`
        background-color: surface-hover;
      `,
    },
    separator: noDefault,
    anchor: noDefault,
    link: noDefault,
    checkbox: noDefault,
    radio: noDefault,
  },
  notification: {
    default: css`
      border: sm;
      border-radius: none;
      border-left: md;
      border-color: border;
    `,
    info: css`
      border-left-color: info;
    `,
    success: css`
      border-left-color: success;
    `,
    error: css`
      border-left-color: error;
    `,
    warning: css`
      border-left-color: warning;
    `,
    question: css`
      border-left-color: question;
    `,
  },
  /**
   * Panel is shared by Select, Combobox, DateInput, Menu
   * Popover, InfoBubble,
   */
  panel: css`
    color: text;
    background-color: surface-1;
    border-radius: md;
    box-shadow: menu;
    border: sm;
    border-color: border;
  `,
  popover: {
    panel: noDefault,
    arrow: noDefault,
    actions: noDefault,
  },
  progressBar: {
    trail: css`
      border-radius: md;
      background-color: #c8d1da;
    `,
    stroke: css`
      background-color: primary;
    `,
  },
  radio: {
    default: css`
      border: sm;
      border-color: selected;

      :disabled {
        border-color: disabled;
      }

      & + [role='presentation'] {
        /** 
        * This makes sure the element with role="presentation" 
        * is visible when hovered 
        */
        background-color: selected;
      }
    `,
    checked: css`
      /** This targets the element with role="presentation" */
      background-color: selected;
    `,
    container: css``,
  },
  rating: {
    default: noDefault,
    leafs: noDefault,
    bars: noDefault,
    stars: noDefault,
  },
  search: {
    default: noDefault,
  },
  searchDialog: {
    dialog: noDefault,
    header: noDefault,
    closeButton: noDefault,
    footer: noDefault,
    kbd: css`
      margin: 0 0.1rem;
      padding: 0.1rem 0.6rem;
      border-radius: sm;
      border: sm;
      border-color: border;
      color: text;
      line-height: 1.4;
      font-family: body;
      font-size: 0.625rem;
      font-weight: bold;
      display: inline-block;
      box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.2),
        inset 0px 0px 0px 1px rgba(255, 255, 255, 0.1);
      background-color: surface-1;
      line-height: 0.625rem;
    `,
  },
  segment: {
    wrapper: css`
      border: sm;
      border-color: secondary;

      &:not(:first-of-type) {
        border-left-width: 0;
      }
    `,
    default: css`
      color: secondary;
      border-radius: none;
    `,
    checked: css`
      background-color: secondary;
      fill: white;
      color: white;
    `,
  },
  select: {
    default: noDefault,
    listBox: noDefault,
    item: {
      default: noDefault,
      focus: css`
        background-color: #ebf6f5;
      `,
      highlighted: css`
        background-color: surface-hover;
      `,
      selected: css`
        color: selected;
        > svg {
          color: selected;
        }
      `,
      selectedDisabled: css`
        background-color: #0071eb33;
      `,
    },
    groupHeader: css`
      font-size: sm;
      color: text-label;
      border-top: sm;
      border-color: border;
      font-weight: bold;
      text-transform: uppercase;
    `,
  },
  separator: noDefault,
  slider: {
    default: noDefault,
    sizes: {
      sm: css`
        height: 0.125rem;
      `,
      md: css`
        height: 0.25rem;
      `,
      lg: css`
        height: 0.5rem;
      `,
    },
    track: css`
      background-color: border;
      &.slider__track-0,
      &.slider__range-track-1 {
        background-color: secondary;
      }
    `,
    thumb: css`
      background-color: white;
      border-radius: rounded;
      box-shadow: md;
    `,
    value: css`
      color: white;
      background-color: secondary;
      border-radius: md;
    `,
  },
  spinner: {
    /** track color */
    borderColor: 'border',
    /** segment color */
    borderTopColor: 'secondary',
  },
  stepper: {
    default: css``,
    item: css``,
  },
  switch: {
    default: css`
      border: sm;
      border-color: border;
      border-radius: rounded;
      background-color: '#EAEAEA';
      height: 1rem;
      width: 2.5rem;
      & + [data-indicator] {
        width: 1.25rem;
        height: 1.25rem;
        left: -0.125rem;
        top: -0.125rem;
        border-radius: rounded;
        background-color: white;
        box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
          0px 1px 1px 0px rgba(0, 0, 0, 0.14),
          0px 1px 3px 0px rgba(0, 0, 0, 0.12);
      }
    `,
    checked: css`
      background-color: selected;
      & + [data-indicator] {
        transform: translateX(1.4rem);
      }
    `,
  },
  table: {
    default: css`
      thead {
      }

      thead th,
      tbody th {
        font-weight: medium;
        text-transform: uppercase;
        font-size: sm;
        color: text-label;
        vertical-align: middle;
        line-height: 1.125rem;
      }

      tbody {
        line-height: 1.125rem;
        font-size: md;
        vertical-align: middle;
      }

      tbody tr:hover {
        background-color: surface-hover;
      }

      tbody tr[data-selected='true'],
      tbody tr[aria-selected='true'] {
        background-color: selected;
      }
    `,
    variants: {
      normal: {
        'th, td': {
          borderTop: 'sm',
          borderBottom: 'sm',
          borderColor: 'border',
        },
      },
      keyValue: {
        'th, td': {
          borderTop: 'sm',
          borderBottom: 'sm',
          borderColor: 'border',
        },
      },
      flipped: {
        'th, td': {
          borderLeft: 'sm',
          borderColor: 'border',
        },
      },
    },
    densities: {
      dense: css`
        td,
        th {
          padding: 0.455rem;
          padding-bottom: 0.4125rem;
          height: 2rem;
        }
      `,
      normal: css`
        td,
        th {
          padding: 0.5rem;
          padding-bottom: 0.4125rem;
          height: 2.5rem;
          /** This td has an irregular shape */
          &[data-type='symbol'] {
            padding-bottom: 0.625rem;
          }
        }
      `,
      spacious: css`
        td,
        th {
          padding: 1.175rem;
          padding-bottom: 1.1125rem;
          height: 3.5rem;
        }
      `,
    },
    rowSelected: noDefault,
    rowExpanded: noDefault,
    stickyHeader: {
      zIndex: 1,
      backgroundColor: '#FFFFFFEE',
    },
    toggleRowsButton: css`
      display: table-cell;
      vertical-align: middle;
      color: primary;
      height: 2.5rem;
      border-bottom: sm;
      border-color: border;
      &:hover,
      &:focus {
        background-color: surface-hover;
        text-decoration: underline;
      }
    `,
    cells: {
      sortableHeader: css`
        &:focus,
        &:hover {
          color: secondary;
        }
      `,
      link: css`
        &:hover,
        &:focus {
          color: link;
          text-decoration: underline;
        }
      `,
      trend: {
        pos: {
          color: 'text-pos',
        },
        neg: {
          color: 'text-neg',
        },
      },
      flash: {
        pos: css`
          background-color: pos;
          color: inverse-text;
        `,
        neg: css`
          background-color: neg;
          color: inverse-text;
        `,
        flat: css`
          background-color: flat;
          color: text;
        `,
      },
    },
  },
  tabs: {
    default: noDefault,
    variants: {
      primary: css`
        flex: 1;
        padding: 3 1;
        font-size: md;
        color: text;
        border-bottom: md;
        border-bottom-color: transparent;
        background-color: surface-2;
        &:focus {
        }
        &:hover {
          background-color: surface-hover;
        }
        &[aria-selected='true'] {
          color: secondary;
          border-bottom-color: secondary;
        }
      `,
      secondary: css`
        padding: 1 2;
        color: secondary;
        border-radius: md;
        & + * {
          margin-left: 2;
        }
        &:focus {
          background-color: secondary-focus;
        }
        &:hover {
          background-color: secondary-hover;
        }
        &[aria-selected='true'] {
          background-color: secondary;
        }
        &:focus,
        &:hover,
        &[aria-selected='true'] {
          color: white;
        }
      `,
    },
  },
  text: {
    default: noDefault,
    variants: {
      heading1: css`
        font-size: 4xl;
        font-weight: light;
        line-height: 1.25;
      `,
      heading2: css`
        font-size: 3xl;
        font-weight: light;
        line-height: 1.25;
      `,
      heading3: css`
        font-size: 2xl;
        font-weight: light;
        line-height: 1.25;
      `,
      heading4: css`
        font-size: xl;
        font-weight: normal;
        line-height: 1.25;
      `,
      heading5: css`
        font-size: lg;
        font-weight: bold;
        line-height: 1.25;
      `,
      heading6: css`
        font-size: md;
        font-weight: bold;
        line-height: 1.25;
      `,
      body1: css`
        font-size: sm;
        line-height: 1rem;
      `,
      body2: css`
        font-size: md;
        line-height: 1.125rem;
      `,
      body3: css`
        font-size: lg;
        line-height: 1.5rem;
      `,
      subtitle1: css`
        font-size: md;
        line-height: 1.125rem;
      `,
      subtitle2: css`
        font-size: lg;
        line-height: 1.5rem;
      `,
      button: css`
        font-size: md;
        line-height: 1.125rem;
      `,
      caption: noDefault,
      overline: noDefault,
      label: noDefault,
      message: noDefault,
      error: noDefault,
    },
    highlighted: css`
      color: selected;
      font-weight: bold;
    `,
  },
  textInput: {
    input: css`
      /** avoid weird iOS zoom issues */
      font-size: lg;

      @media only screen and (min-width: 26.25em) {
        font-size: md;
      }
    `,
    wrapper: {
      /**
       * The wrapping element should reflect
       * the state of the input.
       * */
      default: css`
        height: 2.5rem;
        border: sm;
        border-color: border;
        border-radius: md;
        background-color: input;
      `,
      hover: css`
        border-color: contrast-border;
      `,
      focus: css`
        background-color: input-focus;
        border-color: border-focus;
        box-shadow: focus;
      `,
      error: css`
        border-color: border-error;
      `,
      focusError: css`
        background-color: input-focus;
        border-color: border-error;
        box-shadow: focus-error;
      `,
      disabled: css`
        color: text-disabled;
        background-color: #f2f4f6;
        cursor: not-allowed;
      `,
    },
  },
  tooltip: {
    default: css`
      color: inverse-text;
      font-size: sm;
      background-color: #333;
      opacity: 0.95;
      padding: 1 2;
      border-radius: md;
    `,
  },
};

const defaults = {
  fonts: {
    heading: `-apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`,
    body: `-apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`,
    label: `-apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`,
  },
  shadows: {
    sm: '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)',
    md: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
    lg:
      '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)',
    xl:
      '0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)',
    '2xl': '0 25px 50px -12px rgba(0, 0, 0, 0.25)',
    inner: 'inset 0 2px 4px 0 rgba(0,0,0,0.06)',
    none: 'none',
  },
  letterSpacings: {
    tighter: '-0.05em',
    tight: '-0.025em',
    normal: '0',
    wide: '0.025em',
    wider: '0.05em',
    widest: '0.1em',
  },
  lineHeights: {
    normal: 'normal',
    none: '1',
    shorter: '1.25',
    short: '1.375',
    base: '1.5',
    tall: '1.625',
    taller: '2',
  },
  fontWeights: {
    hairline: 100,
    thin: 200,
    light: 300,
    normal: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
    extrabold: 800,
    black: 900,
  },
  fontSizes: {
    xs: '0.625rem',
    sm: '0.75rem',
    md: '0.875rem',
    lg: '1rem',
    xl: '1.25rem',
    '2xl': '1.75rem',
    '3xl': '2rem',
    '4xl': '2.625rem',
  },
  space: {
    px: '1px',
    0: '0',
    '1': '0.25rem',
    '2': '0.5rem',
    '3': '0.75rem',
    '4': '1rem',
    '5': '1.25rem',
    '6': '1.5rem',
    '8': '2rem',
    '10': '2.5rem',
    '12': '3rem',
    '16': '4rem',
    '20': '5rem',
    '24': '6rem',
    '32': '8rem',
    '40': '10rem',
    '48': '12rem',
    '56': '14rem',
    '64': '16rem',
  },
  breakpoints: {
    0: 0,
    1: '26.25em',
    2: '52.5em',
    3: '80em',
    4: '120em',
    5: '160em',
    base: 0,
    sm: '26.25em',
    md: '52.5em',
    lg: '80em',
    xl: '120em',
    '2xl': '160em',
  },
  zIndices: {
    hide: -1,
    auto: 'auto',
    '0': 0,
    '10': 10,
    '20': 20,
    '30': 30,
    '40': 40,
    '50': 50,
    '60': 60,
    '70': 70,
    '80': 80,
  },
  radii: {
    none: '0',
    sm: '0.125rem',
    md: '0.25rem',
    lg: '0.5rem',
    rounded: '9999px',
  },
  duration: {
    instant: '150ms',
    normal: '300ms',
    long: '450ms',
  },
  timing: {
    spring: 'cubic-bezier(0.175, 0.885, 0.32, 1.275)',
    lineair: 'lineair',
  },
  opacity: {
    '0': '0',
    '20%': '0.2',
    '40%': '0.4',
    '60%': '0.6',
    '80%': '0.8',
    '100%': '1',
  },
  borders: {
    none: 0,
    sm: '1px solid',
    md: '2px solid',
    lg: '4px solid',
  },
  borderWidths: {
    none: 0,
    sm: '1px',
    md: '2px',
    lg: '4px',
  },
};

export const theme = ({
  ...defaults,
  googleFonts,
  fonts: {
    ...defaults.fonts,
    ...fonts,
  },
  shadows: {
    ...defaults.shadows,
    ...shadows,
  },
  colors,
  ...components,
} as unknown) as typeof defaultTheme;
