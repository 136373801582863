import 'whatwg-fetch';
import template from 'url-template';
import { logoutUser } from './auth-utils';

export default class URLEncodedFormData extends String {
  constructor(formElement) {
    super();

    if (!(formElement instanceof HTMLFormElement)) {
      throw new Error(`URNEncodedFormData requires a form, got ${typeof form}`);
    }

    this.formElement = formElement;
  }

  toString() {
    const urlEncodedDataPairs = [];
    const inputs = this.formElement.getElementsByTagName('input');

    // Turn the data object into an array of URL-encoded key/value pairs.
    // for-loop used on purpose to loop over HTMLCollection
    for (let index = 0, len = inputs.length; index < len; index++) {
      const { name, value } = inputs[index];
      urlEncodedDataPairs.push(
        `${encodeURIComponent(name)}=${encodeURIComponent(value)}`,
      );
    }

    // Combine the pairs into a single string and replace all %-encoded spaces to
    // the '+' character; matches the behaviour of browser form submissions.
    return urlEncodedDataPairs.join('&').replace(/%20/g, '+');
  }
}

const buildResource = resource => {
  if (typeof resource === 'string') {
    return resource;
  } else if (resource.url) {
    const { url, ...variables } = resource;
    const parsedUrl = template.parse(url);

    return parsedUrl.expand(variables);
  }

  throw new Error(
    'Resource must be either a string or an object with a url template property and variables. ' +
      `Got ${resource}.`,
  );
};

const getContentType = body => {
  let contentType = 'application/json; charset=UTF-8';
  if (body instanceof FormData) {
    contentType = 'multipart/form-data; charset=UTF-8';
  } else if (body instanceof URLEncodedFormData) {
    contentType = 'application/x-www-form-urlencoded; charset=UTF-8';
  }

  return contentType;
};

export const getRaw = (resource, headers) => {
  return fetch(buildResource(resource), {
    credentials: 'include',
    headers: headers ? new Headers(headers) : {},
  });
};

export const get = async (resource, headers) => {
  const response = await getRaw(resource, headers);
  if (response.status === 401) {
    logoutUser();
    window.location.assign('/admin/login');
    return;
  }
  if (!response.ok) {
    throw response;
  }
  return await response.json();
};

export const post = (resource, body, headers) => {
  let parsedBody;
  if (!body) {
    parsedBody = undefined;
  } else if (body && getContentType(body).includes('multipart/form-data')) {
    parsedBody = body;
  } else {
    parsedBody = body.toString();
  }

  return fetch(buildResource(resource), {
    method: 'POST',
    credentials: 'include',
    body: parsedBody,
    headers: new Headers({
      'Content-Type': getContentType(body),
      ...headers,
    }),
  });
};

export const filePost = (resource, body, headers) => {
  let parsedBody;
  if (!body) {
    parsedBody = undefined;
  } else if (body && getContentType(body).includes('multipart/form-data')) {
    parsedBody = body;
  } else {
    parsedBody = body.toString();
  }

  return fetch(buildResource(resource), {
    method: 'POST',
    credentials: 'include',
    body: parsedBody,
    headers: new Headers({
      ...headers,
    }),
  });
};

export const filePut = (resource, body, headers) => {
  let parsedBody;
  if (!body) {
    parsedBody = undefined;
  } else if (body && getContentType(body).includes('multipart/form-data')) {
    parsedBody = body;
  } else {
    parsedBody = body.toString();
  }

  return fetch(buildResource(resource), {
    method: 'PUT',
    credentials: 'include',
    body: parsedBody,
    headers: new Headers({
      ...headers,
    }),
  });
};

export const put = (resource, body, headers) => {
  return fetch(buildResource(resource), {
    method: 'PUT',
    credentials: 'include',
    body: body ? body.toString() : undefined,
    headers: new Headers({
      'Content-Type': getContentType(body),
      ...headers,
    }),
  });
};

export const del = (resource, headers) => {
  return fetch(buildResource(resource), {
    method: 'DELETE',
    credentials: 'include',
    headers: headers ? new Headers(headers) : {},
  });
};
