import { useLayoutEffect } from 'react';
import { useFormState } from 'react-final-form';
import { supportsBroadcastChannel } from 'utils/userAgent';

export const THEME_CHANNEL = 'theme-channel';

const createChannel = () => {
  if (supportsBroadcastChannel) {
    const tbc = new BroadcastChannel(THEME_CHANNEL);

    return async function update({ token, theme }: any) {
      try {
        if (token && theme) {
          tbc.postMessage({ token, theme });
        }
      } catch (err) {
        console.warn('[ThemeBroadcaster]', err);
      }
    };
  } else {
    return (args: any) => void {};
  }
};

const update = createChannel();

export function ThemeBroadcaster({ token }: { token?: string }) {
  const form = useFormState({ subscription: { values: true } });
  useLayoutEffect(() => {
    if (supportsBroadcastChannel) {
      update({ token, theme: form.values });
    }
  }, [form.values, token]);

  return null;
}
