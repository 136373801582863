import React from 'react';
import { Trans } from '@lingui/macro';
import { Stack } from '@oms/ui-stack';
import { Text } from '@oms/ui-text';
import { Link } from '@oms/ui-link';
import { useCustomerByToken } from 'client/customers';

import { Product } from 'client/products-utils';

const getIncluded = (productName: string, products?: Product[]) => {
  return products?.find(product => product.key === productName);
};

function Guard({ children, irModule, token }: GuardProps) {
  const customer = useCustomerByToken(token);
  const included = getIncluded(irModule, customer?.products);
  return included ? (
    <>{children}</>
  ) : (
    <Stack p={4} border="sm" borderColor="border" gap={4} alignment="center">
      <Text variant="heading5">
        <Trans>
          The module is currently not part of your subscription plan.
        </Trans>
      </Text>
      <Text mt={4}>
        <Trans>
          For more information, or if you think the component was disabled by
          mistake, please contact{' '}
        </Trans>
        <Link as="a" href="mailto:s-ir@oslomarketsolutions.no" variant="link">
          Oslo Market Solutions
        </Link>
      </Text>
    </Stack>
  );
}

interface GuardProps {
  children: React.ReactNode;
  irModule: string;
  token: string;
}

export { Guard };
