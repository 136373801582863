import React, { useCallback, useState } from 'react';
import useIntersectionObserverRef from '@rooks/use-intersection-observer-ref';
import styled from '@xstyled/emotion';
import { th } from '@xstyled/system';
import { Icon, light } from '@oms/ui-icon';
import { mixins } from '@oms/ui-utils';

const Button = styled.button`
  ${mixins.borderBox}
  ${mixins.anchor}
  ${mixins.button}
  ${mixins.textButton}
  ${th('button.default')}
  ${th('button.variants.secondary')}
  ${th('button.sizes.md')}
  padding-left:3;
  padding-right:3;
  position:fixed;
  bottom: 1.5rem;
  right: 1.5rem;
  background-color: white;
  box-shadow: lg;
  border-radius: rounded;
  [data-left] {
    margin-right:2;
  }
`;

function ScrollToTopButton({ rootMargin = '75px' }: { rootMargin?: string }) {
  const [visible, setVisible] = useState(false);
  const callback = useCallback((entries: IntersectionObserverEntry[]) => {
    const height = window.document.body.scrollHeight > window.innerHeight * 1.5;
    const visible =
      height &&
      (entries?.[0]?.isIntersecting ||
        ((entries as any)?.[0]?.isVisible as boolean));

    setVisible(visible);
  }, []);
  const [ref] = useIntersectionObserverRef(callback, {
    rootMargin,
  });
  const scroll = useCallback(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  return (
    <>
      <div ref={ref} data-scroll-to-top />
      {visible ? (
        <Button onClick={scroll}>
          <span data-left>
            <Icon icon={light.faAngleUp} />
          </span>
          Scroll to top
        </Button>
      ) : null}
    </>
  );
}

export { ScrollToTopButton };
