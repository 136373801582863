import React from 'react';
import { Field } from '@oms/ui-field';
import { DisclosureSection } from 'components/DisclosureSection';
import { ColorInput } from './ColorInput';
import { validateColorInput } from './utils';

function MarketTrends() {
  return (
    <DisclosureSection title="Market trends">
      <Field
        as={ColorInput}
        name="posColor"
        label="Positive trend color"
        validate={validateColorInput}
      />
      <Field
        as={ColorInput}
        name="negColor"
        label="Negative trend color"
        validate={validateColorInput}
      />
    </DisclosureSection>
  );
}

export { MarketTrends };
