import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  AppNavigationBar as NavigationBar,
  AppNavigationDrawer,
  AppNavigationDrawerSection,
} from '@oms/ui-app-shell';
import { Box } from '@oms/ui-box';
import { Stack } from '@oms/ui-stack';
import { Link } from '@oms/ui-link';
import { Avatar } from '@oms/ui-avatar';
import { Menu, MenuItem } from '@oms/ui-menu';
import { useAuth, useLogout } from 'client/auth';
import logo from './logo.svg';
import { prefetch } from 'utils/prefetch';

const defaultRoutes = [
  { path: '/admin/', label: 'Home' },
  { path: '/admin/customers', label: 'Customers' },
  { path: '/admin/admins', label: 'Administrators' },
  { path: '/admin/products', label: 'Products' },
  { path: '/admin/settings', label: 'Settings' },
  { path: '/public/demo/pages-and-feeds', label: 'Public view' },
];

function AppNavigationBar() {
  const { data: user } = useAuth();
  const [logout] = useLogout();

  const navigationElement = (
    <AppNavigationDrawer aria-label="Menu">
      <Stack>
        <AppNavigationDrawerSection title="Menu" routes={defaultRoutes} />
      </Stack>
    </AppNavigationDrawer>
  );

  return (
    <NavigationBar
      logo={
        <RouterLink to="/" style={{ gridArea: 'logo' }}>
          <Box
            as="img"
            alt="IR Web modules"
            src={logo}
            height="2.5rem"
            mx={2}
          />
        </RouterLink>
      }
      navigation={user?.authenticated ? navigationElement : undefined}
    >
      <Box
        display="flex"
        alignItems="center"
        width="100%"
        ml={{ base: 'auto', md: 'initial' }}
        justifyContent={{ base: 'initial', md: 'space-between' }}
      >
        {user?.authenticated ? (
          <Menu
            disclosure={<Avatar name={user.principals?.[0]?.sub} ml="auto" />}
          >
            <MenuItem onSelect={() => logout()}>Logout</MenuItem>
          </Menu>
        ) : (
          <Link
            to="/admin/login"
            borderRadius="rounded"
            ml="auto"
            onMouseEnter={prefetch(import('pages/Login'))}
          >
            Login
          </Link>
        )}
      </Box>
    </NavigationBar>
  );
}

export { AppNavigationBar };
