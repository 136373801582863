import debounce from 'just-debounce-it';

// //////////////////////////////////////////////////////////////////
// See doc/postmessage.md for documentation of postMessage methods //
// --------------------------------------------------------------- //
//     Please keep that document up do date when editing the       //
//             postMessage protocol                                //
// //////////////////////////////////////////////////////////////////

const sendMessage = payload => {
  if (window.postMessage) {
    window.parent.postMessage(JSON.stringify(payload), '*');
  }
};

// eslint-disable-next-line import/prefer-default-export
export const sendResizeMessage = debounce(() => {
  sendMessage({
    message: 'resize',
    name: window.name,
    height: document.documentElement.offsetHeight,
  });
}, 200);
