import React from 'react';
import { Field, Fieldset } from '@oms/ui-field';
import { Segment, SegmentGroup } from '@oms/ui-segment';
import { DisclosureSection } from 'components/DisclosureSection';
import { ColorInput } from './ColorInput';
import { validateColorInput } from './utils';

// TODO Add support for chart styles.
// +++++++++++++++++++++++++++++++++++++
// import { OnChange } from './OnChange';

function Chart() {
  return (
    <DisclosureSection title="Chart">
      <Field
        as={ColorInput}
        name="chartLineColor"
        label="Line color"
        validate={validateColorInput}
        message={
          <span>
            When the <code>rgb</code> format is used, the transparency on the
            scrubber is preserved.
          </span>
        }
      />
      <Field
        as={ColorInput}
        name="chartTextColor"
        label="Axis label text color"
        validate={validateColorInput}
      />
      <Field
        as={ColorInput}
        name="chartGridLineColor"
        label="Grid line color"
        validate={validateColorInput}
      />
      <Fieldset as={SegmentGroup} name="chartFill" legend="Fill">
        <Field as={Segment} label="none" value="none" />
        <Field as={Segment} label="solid" value="solid" />
        <Field as={Segment} label="gradient" value="gradient" />
      </Fieldset>
      {/*<OnChange name="chartFill">
        {(value: 'none' | 'solid' | 'gradient') => {
          if (value === 'solid')
            return (
              <Field
                as={ColorInput}
                name="chartFillTopColor"
                label="Fill color"
                validate={validateColorInput}
              />
            );
          if (value === 'gradient')
            return (
              <>
                <Field
                  as={ColorInput}
                  name="chartFillTopColor"
                  label="Gradient start color"
                  validate={validateColorInput}
                />
                <Field
                  as={ColorInput}
                  name="chartFillBottomColor"
                  label="Gradient end color"
                  validate={validateColorInput}
                />
              </>
            );
          return null;
        }}
      </OnChange>*/}
    </DisclosureSection>
  );
}

export { Chart };
