import React from 'react';

import { SnapshotSkeleton } from 'components/Snapshot/SnapshotSkeleton';
import { ChartSkeleton } from 'components/AnalysisChart/ChartSkeleton';
import { ProfitCalculatorSkeleton } from 'components/Calculator/ProfitCalculatorSkeleton';
import { OrderDepthSkeleton } from 'components/OrderDepth/OrderDepthSkeleton';
import { PeerGroupSkeleton } from 'components/PeerGroup/PeerGroupSkeleton';
import { CompanyInfoSkeleton } from 'components/CompanyInfo/CompanyInfoSkeleton';
import { TradesSkeleton } from 'components/Trades/TradesSkeleton';

const fallbacks: Record<string, React.ReactElement> = {
  standardPage: <React.Fragment />,
  snapshot: <SnapshotSkeleton />,
  graphInteractive: <ChartSkeleton />,
  profitCalculator: <ProfitCalculatorSkeleton token="" />,
  orderDepth: <OrderDepthSkeleton />,
  trades: <TradesSkeleton />,
  peerGroupBenchmark: <PeerGroupSkeleton />,
  companyInfo: <CompanyInfoSkeleton />,
  disclaimer: <div>...loading</div>,
};

interface ModuleFallbackProps {
  irModule?: string;
}

const ModuleFallback = React.memo(({ irModule }: ModuleFallbackProps) => {
  return fallbacks[irModule || 'standardPage'];
});

export { ModuleFallback };
