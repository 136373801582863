import * as React from 'react';
import { Field } from '@oms/ui-field';
import { Stack } from '@oms/ui-stack';
import { TextInput } from '@oms/ui-text-input';
import { NumberInput } from '@oms/ui-number-input';
import { createForm } from './createForm';
import { GRAPH_INTERACTIVE } from 'constants/modules';

const [GraphInteractive] = createForm(GRAPH_INTERACTIVE.key, () => (
  <>
    <Stack orientation="horizontal" gap={4}>
      <Field as={NumberInput} name="width" label="Width" flex={1} />
      <Field as={NumberInput} name="height" label="Height" flex={1} />
    </Stack>

    <Field as={TextInput} name="compare" label="Compare" />
    <Field
      as={TextInput}
      name="chartBackgroundPaint"
      label="Chart background paint"
    />
    <Field
      as={TextInput}
      name="plotBackgroundPaint"
      label="Plot background paint"
    />
    <Field as={TextInput} name="color1" label="Color 1" />
    <Field as={TextInput} name="color2" label="Color 2" />
  </>
));

export type GraphInteractiveFormValues = {
  width: number;
  height: number;
  compare: string;
  chartBackgroundPaint: string;
  plotBackgroundPaint: string;
  color1: string;
  color2: string;
};

export { GraphInteractive };
