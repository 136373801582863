import { useLayoutEffect } from 'react';

export function prefetch(...promises: Promise<any>[]) {
  return async () => {
    try {
      if (Array.isArray(promises)) {
        await Promise.all([...promises]);
      } else {
        await promises;
      }
    } catch (error) {
      console.warn(`Cannot prefetch component. ${error}"`);
    }
  };
}

export function usePrefetch(...promises: Promise<any>[]) {
  useLayoutEffect(() => {
    const callback = prefetch(...promises);
    callback();
  }, [promises]);
}
