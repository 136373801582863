/**
 * A number of modules/components have been disabled as they are not ready for release 3.2.0
 */
import React from 'react';
import { Switch, useParams } from 'react-router-dom';
import { ModuleProvider } from 'components/ModuleProvider';
import { Route } from 'components/Route';
import { StandardPage } from 'components/StandardPage';
import { modules } from 'constants/modules';
import { useSearchParams } from 'utils/useSearchParams';

const Calculator = React.lazy(() => import('components/Calculator'));
// const Chart = React.lazy(() => import('components/Chart'));
const AnalysisChart = React.lazy(() => import('components/AnalysisChart'));
const CompanyInfo = React.lazy(() => import('components/CompanyInfo'));
const HistoryExport = React.lazy(() => import('components/HistoryExport'));
const OrderDepth = React.lazy(() => import('components/OrderDepth'));
const PeerGroup = React.lazy(() => import('components/PeerGroup'));
const Snapshot = React.lazy(() => import('components/Snapshot'));
const Trades = React.lazy(() => import('components/Trades'));
const Disclaimer = React.lazy(() => import('components/Disclaimer'));
const Shareholders = React.lazy(() => import('components/Shareholders'));

const ShareholdersDisclaimer = React.lazy(() =>
  import('components/ShareholdersDisclaimer'),
);
const SharePerformance = React.lazy(() =>
  import('components/SharePerformance'),
);
const FinancialCalendar = React.lazy(() => import('components/Calendar'));
const CompanyDisclosure = React.lazy(() =>
  import('components/CompanyDisclosure'),
);
const CompanyDisclosureArchive = React.lazy(() =>
  import('components/CompanyDisclosureArchive'),
);
const DividendHistory = React.lazy(() => import('components/DividendHistory'));

function ModuleRoutes(_: ModuleRoutesProps) {
  const { irModule } = useParams<{ irModule: string }>();
  const { token = 'demo', lang = 'en' } = useSearchParams();
  return (
    <ModuleProvider irModule={irModule} token={token} lang={lang}>
      <Switch>
        <Route path="/component/__TEST__" element={<></>} />
        <Route
          path={modules.STANDARD_PAGE.path}
          element={<StandardPage token={token} />}
        />
        <Route
          path={modules.PROFIT_CALCULATOR.path}
          element={<Calculator token={token} standalone />}
        />
        <Route
          path={modules.GRAPH_INTERACTIVE.path}
          element={<AnalysisChart token={token} />}
        />
        <Route
          path={modules.HISTORY_EXPORT.path}
          element={<HistoryExport token={token} />}
        />
        <Route
          path={modules.COMPANY_INFO.path}
          element={<CompanyInfo token={token} />}
        />
        <Route
          path={modules.ORDER_DEPTH.path}
          element={<OrderDepth token={token} />}
        />
        <Route
          path={modules.PEER_GROUP_BENCHMARK.path}
          element={<PeerGroup token={token} lang={lang} />}
        />
        <Route
          path={modules.SNAPSHOT.path}
          element={<Snapshot token={token} />}
        />
        <Route path={modules.TRADES.path} element={<Trades token={token} />} />
        <Route path={modules.DISCLAIMER.path} element={<Disclaimer />} />
        <Route
          path="/component/disclaimerShareholders"
          element={<ShareholdersDisclaimer />}
        />

        <Route
          path={modules.SHAREHOLDERS.path}
          element={<Shareholders token={token} />}
        />
        <Route
          path={modules.COMPANY_DISCLOSURES.path}
          element={<CompanyDisclosure token={token} />}
        />
        <Route
          path={modules.COMPANY_DISCLOSURES_ARCHIVE.path}
          element={<CompanyDisclosureArchive token={token} />}
        />
        <Route
          path={modules.DIVIDEND_HISTORY.path}
          element={<DividendHistory token={token} />}
        />
        <Route
          path={modules.FINANCIAL_CALENDAR.path}
          element={<FinancialCalendar token={token} lang={lang} />}
        />
        <Route
          path={modules.SHARE_PERFORMANCE.path}
          element={<SharePerformance token={token} />}
        />
        {/*<Route
          path={modules.GRAPH_IMAGE.path}
          element={<Chart token={token} />}
        />*/}
        <Route
          path={modules.SHARE_PERFORMANCE.path}
          element={<SharePerformance token={token} />}
        />
      </Switch>
    </ModuleProvider>
  );
}

interface ModuleRoutesProps {}

export { ModuleRoutes };
