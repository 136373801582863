import { useField } from 'react-final-form';

function OnChange({ name, children }: OnChangeProps) {
  const field = useField(name, {
    subscription: {
      value: true,
    },
  });

  return children(field?.input?.value);
}

export interface OnChangeProps {
  name: string;
  children: (value: any) => JSX.Element | null;
}

export { OnChange };
