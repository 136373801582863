import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { matchSorter } from 'match-sorter';
import { Field } from '@oms/ui-field';
import { Search } from '@oms/ui-search';
import { fontNames } from './utils';

function FontField({ name, disabled }: FontFieldProps) {
  const { value, onChange } = useInput('');

  const { data: items = [], status } = useQuery(
    ['fontsFamily', { value }],
    () => matchSorter(fontNames, value),
    {
      enabled: value.length >= 2,
    },
  );

  return (
    <Field
      as={Search}
      name={name}
      label="Font family"
      onInputValueChange={onChange}
      items={items}
      status={status}
      minimumQueryLength={2}
      debounce={200}
      disabled={disabled}
    />
  );
}

function useInput(initialValue = '') {
  const [value, setValue] = useState(initialValue);
  const onChange = (event: InputEvent<string>) => {
    setValue(event?.target?.value || (event as any));
  };
  return { value, onChange };
}

type InputEvent<T extends any = ''> = React.ChangeEvent<HTMLInputElement> & {
  target: HTMLInputElement;
} & { target: { value: T } };

interface FontFieldProps {
  name: string;
  disabled?: boolean;
}

export { FontField };
