import React from 'react';
import { Form, FormProps } from 'react-final-form';
import { Stack, StackProps } from '@oms/ui-stack';
import { Icon } from '@oms/ui-icon';
import { faSave } from '@fortawesome/pro-light-svg-icons';
import { DrawerHeader, DrawerContent } from '@oms/ui-drawer';
import { Button } from '@oms/ui-button';
import { UnsavedChangesNotification } from 'components/UnsavedChangesNotification';

function SettingsForm({
  children,
  headerText = 'Settings',
  ...formProps
}: SettingsFormProps) {
  return (
    <Form
      subscription={{ pristine: true, submitting: true, hasSubmitErrors: true }}
      {...formProps}
    >
      {({ handleSubmit, pristine, submitting }) => (
        <>
          <DrawerHeader
            action={
              <Button
                form="admin-form"
                type="submit"
                isPending={submitting}
                leftElement={<Icon icon={faSave as any} />}
              >
                {submitting ? 'Saving' : 'Save'}
              </Button>
            }
          >
            {headerText}
          </DrawerHeader>
          <UnsavedChangesNotification pristine={pristine} />
          <DrawerContent id="admin-form" as="form" onSubmit={handleSubmit}>
            <Stack gap={4}>{children}</Stack>
          </DrawerContent>
        </>
      )}
    </Form>
  );
}

export interface SettingsFormProps extends FormProps {
  children: StackProps['children'];
  headerText?: string;
}

export { SettingsForm };
