import * as React from 'react';
import { Field } from '@oms/ui-field';
import { Stack } from '@oms/ui-stack';
import { TextInput } from '@oms/ui-text-input';
import { NumberInput } from '@oms/ui-number-input';
import { createForm } from './createForm';
import { SHAREHOLDERS_COUNTRY } from 'constants/modules';

const [ShareholdersCountry] = createForm(SHAREHOLDERS_COUNTRY.key, () => (
  <>
    <Field as={NumberInput} name="count" label="Count" />
    <Stack orientation="horizontal" gap={4}>
      <Field as={NumberInput} name="width" label="Width" flex={1} />
      <Field as={NumberInput} name="height" label="Height" flex={1} />
    </Stack>

    <Field as={TextInput} name="background" label="Background" />
    <Field as={TextInput} name="shadow" label="Shadow" />
    <Field as={TextInput} name="colors" label="Colors" />
  </>
));

export type ShareholdersCountryFormValues = {
  count: number;
  width: number;
  height: number;
  background: string;
  shadow: string;
  colors: string;
};

export { ShareholdersCountry };
