let win;
if (typeof window === 'undefined') {
  win = { location: {} };
} else {
  win = window;
}
// "https://ir.apps.dev.oms.no";

export const HOSTNAME = `${window.location.protocol}//${process.env.JAWS_URL ||
  win.location.hostname}:${window.location.port}`;
export const BASE_URL = `${HOSTNAME}/server/ir`;
export const SECURE_BASE_URL = `${BASE_URL}/secure`;
export const SECURE_ADMIN_URL = `${SECURE_BASE_URL}/admin`;
export const AUTHENTICATED_BASE_URL = `${SECURE_BASE_URL}/auth`;
export const AUTHENTICATED_ADMIN_URL = `${AUTHENTICATED_BASE_URL}/admin`;

// Authentication
export const AUTHENTICATED = `${AUTHENTICATED_BASE_URL}/me`;
export const AUTH_URL = `${AUTHENTICATED_ADMIN_URL}/login`;
export const LOGOUT_URL = `${AUTHENTICATED_ADMIN_URL}/logout`;

// Customers
export const SECURE_CUSTOMERS_URL = `${SECURE_BASE_URL}/customers`;
export const CUSTOMERS_URL = `${BASE_URL}/customers`;

// Products
export const SECURE_PRODUCTS_URL = `${SECURE_BASE_URL}/products`;
export const PRODUCTS_URL = `${BASE_URL}/products`;

// Properties
export const PROPERTIES_URL = `${SECURE_BASE_URL}/properties`;

// Profit Calculator
export const CALCULATOR_URL = `${BASE_URL}/ir/profit-calculator`;

// Administrators
export const ADMIN_REQUEST_PW_URL = `${BASE_URL}/admin/request-password-reset`;
export const ADMIN_RESET_PW_URL = `${BASE_URL}/admin/reset-password`;

export const ONE_DAY = 1000 * 60 * 60 * 24;
