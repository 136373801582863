import React, { useEffect, useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Form } from 'react-final-form';
import { FORM_ERROR } from 'final-form';
import produce from 'immer';
import { faSave } from '@fortawesome/pro-light-svg-icons';
import {
  Sheet,
  SheetHeader,
  SheetContent,
  SheetDisclosure,
  useSheetById,
} from '@oms/ui-sheet';
import { Box } from '@oms/ui-box';
import { Icon, light } from '@oms/ui-icon';
import { Button } from '@oms/ui-button';
import { Link } from '@oms/ui-link';
import { useToast } from '@oms/ui-toast';
import { Notification } from '@oms/ui-notification';
import { Sandbox2, getModuleSource } from 'components/Sandbox';
import { Styles } from 'components/SettingsForms';
import { UnsavedChangesNotification } from 'components/UnsavedChangesNotification';
import { Text } from '@oms/ui-text';
import {
  useScreenSizeSwitcher,
  EditorScreenSizeSwitcher,
} from 'components/EditorScreenSizeSwitcher';
import client from 'client/customers';
import { getIndex } from 'utils/getIndex';
import { defaultTheme } from 'components/ModuleTheme/defaultTheme';
import { ThemeBroadcaster } from 'components/ThemeBroadcaster';

import { CustomerProperties } from 'client/customers-utils';
import { FormValues, ApiValues } from 'components/SettingsForms/Styles';
import { Submit, Validate, Errors } from 'components/SettingsForms/types';
import { MatchParams } from 'admin-routes';

const validate: Validate<FormValues> = _values => {
  let errors: Errors<FormValues> = {};
  return errors;
};

export default function Editor() {
  const sizeState = useScreenSizeSwitcher();
  const { sheet } = useSheetById('editor');
  const toast = useToast();
  const { customerId } = useParams<MatchParams['customers']>();
  const { data: customer } = client.useCustomer(customerId);
  const [mutate] = client.useUpdateCustomer(customerId);
  const initialValues = useMemo(() => getInitialValues(customer), [customer]);

  const submit: Submit<FormValues> = useCallback(
    (values, form, onErrorCallback) => {
      if (customer?.properties.length === 0) {
        onErrorCallback?.({
          [FORM_ERROR]: 'Theme is not assigned to this customer',
        });
        return;
      }
      const payload = createPayload(customer, values);

      const promise = mutate(payload, {
        onSuccess: () => {
          form.initialize(values);
          toast(
            toastProps => (
              <Notification
                title="Changes saved"
                status="success"
                {...toastProps}
              >
                Changes have been saved successfully.
              </Notification>
            ),
            {
              position: 'bottom-left',
              duration: 5000,
            },
          );
        },
        onError: async (error: any) => {
          // Pass the error to final form
          onErrorCallback?.({
            [FORM_ERROR]: error?.statusText || 'Error',
          });
          toast(
            toastProps => (
              <Notification title="Oops" status="error" {...toastProps}>
                Something went wrong. Please try again.
              </Notification>
            ),
            {
              position: 'bottom-left',
              duration: 5000,
            },
          );
        },
      });

      return promise;
    },
    [customer, mutate, toast],
  );

  useEffect(() => {
    sheet.show();
    // eslint-disable-next-line
  }, [sheet.show]);

  return (
    <>
      <Box
        display="flex"
        bg="surface-1"
        justifyContent="space-between"
        mr={-4}
        py={3}
        pl={4}
        pr={8}
        borderBottom="sm"
        borderColor="border"
        style={{ marginTop: -1 }}
      >
        <Link
          to="."
          variant="tertiary"
          leftElement={<Icon icon={light.faAngleLeft} />}
        >
          {customer?.ticker}
        </Link>
        <Box maxHeight="1rem" style={{ transform: 'translateY(-2px)' }}>
          <EditorScreenSizeSwitcher state={sizeState} />
        </Box>
        {sheet.visible ? (
          <span />
        ) : (
          <SheetDisclosure
            sheetId="editor"
            as={Button}
            variant="secondary"
            leftElement={
              <Box
                as="img"
                src="/editor.svg"
                width="0.875rem"
                style={{ transform: 'translateY(2px)' }}
              />
            }
          >
            Editor
          </SheetDisclosure>
        )}
      </Box>
      <Box
        flex={1}
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        {!!customer?.products?.find(
          product => product.key === 'standardPage',
        ) && customer.key ? (
          <Sandbox2
            boxShadow={sizeState.size !== 'lg' ? 'md' : undefined}
            {...sizeState.dimensions}
            src={getModuleSource({ token: customer?.key })}
          />
        ) : (
          <Box center>
            <Text fontFamily={'system-ui' as any}>
              Preview is only available on IR Web modules customers
            </Text>
          </Box>
        )}
      </Box>

      <Sheet sheetId="editor">
        <Form
          validate={validate}
          onSubmit={submit}
          initialValues={initialValues}
          subscription={{ pristine: true, submitting: true }}
        >
          {({ handleSubmit, pristine, submitting }) => (
            <>
              <SheetHeader
                action={
                  <Button
                    type="submit"
                    form="theme-form"
                    isPending={submitting}
                    onClick={handleSubmit}
                    leftElement={<Icon icon={faSave as any} />}
                    disabled={customer?.properties?.length === 0}
                  >
                    {submitting ? 'Saving...' : 'Save'}
                  </Button>
                }
              >
                Editor
              </SheetHeader>
              <ThemeBroadcaster token={customer?.key} />
              <UnsavedChangesNotification pristine={pristine} />
              <SheetContent as="form" id="theme-form" onSubmit={handleSubmit}>
                <Styles />
              </SheetContent>
            </>
          )}
        </Form>
      </Sheet>
    </>
  );
}

function getInitialValues(customer?: CustomerProperties): Partial<FormValues> {
  let initialValues: Record<string, any> = {
    // Remove until defaults are added to all customers
    linkTextColor: defaultTheme.linkTextColor,
    borderColor: defaultTheme.borderColor,
    chartTextColor: defaultTheme.chartTextColor,
    chartGridLineColor: defaultTheme.chartGridLineColor,
    //
    headingFontStyle: {
      variant: undefined,
      label: undefined,
      value: undefined,
    },
    bodyFontStyle: {
      variant: undefined,
      label: undefined,
      value: undefined,
    },
  };

  const properties = customer?.properties;

  if (properties) {
    // Take the flat structure and create a nested structure
    properties.forEach(property => {
      if ((property.key as string) === 'headingFontVariant') {
        initialValues.headingFontStyle.variant = property?.value;
      } else if ((property.key as string) === 'headingFontWeight') {
        initialValues.headingFontStyle.label = property?.value;
        initialValues.headingFontStyle.value = property?.value;
      } else if ((property.key as string) === 'bodyFontVariant') {
        initialValues.bodyFontStyle.variant = property?.value;
      } else if ((property.key as string) === 'bodyFontWeight') {
        initialValues.bodyFontStyle.label = property?.value;
        initialValues.bodyFontStyle.value = property?.value;
      } else {
        initialValues[property.key] = property.value;
      }
    });
    return initialValues;
  } else {
    return {
      ...defaultTheme,
      headingFontStyle: {
        variant: defaultTheme.headingFontVariant,
        label: defaultTheme.headingFontWeight,
        value: defaultTheme.headingFontWeight,
      },
      bodyFontStyle: {
        variant: defaultTheme.bodyFontVariant,
        label: defaultTheme.bodyFontWeight,
        value: defaultTheme.bodyFontWeight,
      },
    };
  }
}

function trim(arg: string | number) {
  return arg.toString().trim();
}

function flattenThemeFormValues({
  headingFontStyle,
  bodyFontStyle,
  ...properties
}: Partial<FormValues>): Partial<ApiValues> {
  // Take the nested structure and create a flat structure
  const values = {
    // Values might contain unintended white spaces
    // We need to trim them before saving
    ...Object.fromEntries(
      Object.entries(properties).map(([key, value]) => [key, trim(value)]),
    ),
    headingFontVariant: headingFontStyle?.variant,
    headingFontWeight: headingFontStyle?.value,
    bodyFontVariant: bodyFontStyle?.variant,
    bodyFontWeight: bodyFontStyle?.value,
  };
  return values;
}

function createPayload(
  customer: CustomerProperties | undefined,
  formValues: FormValues,
) {
  const customerProperties = customer?.properties;
  return produce(customer, (draft: any) => {
    // For each of the form fields ...
    Object.entries(flattenThemeFormValues(formValues)).forEach(
      ([property, value]) => {
        // Find the corresponding index in props
        const index = getIndex(customerProperties, property);
        // Then mutate the original customer object at the index with Immer.
        // We only need to assign a new value. This way we keep the original key and id unchanged
        if (index >= 0) {
          (draft as any).properties[index].value = value;
        } else {
          (draft as any).properties.push({ property, value });
        }
      },
    );
  });
}

export { Editor, createPayload, flattenThemeFormValues };
