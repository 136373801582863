import React from 'react';
import { Box, BoxProps } from '@oms/ui-box';

export interface Sandbox2Props extends Omit<BoxProps, 'children'> {
  src: string;
}

export function Sandbox2({
  token,
  src = getModuleSource({
    irModule: 'standardPage',
    language: 'en',
    token: 'demo',
  }),
  ...props
}: Sandbox2Props) {
  return (
    <Box
      as="iframe"
      flex={1}
      bg="surface-1"
      width="100%"
      mx="auto"
      style={{ transtion: 'all 200ms ease' }}
      src={src}
      {...props}
    />
  );
}

export function getModuleSource({
  url = window.location.origin,
  irModule = 'standardPage',
  token = 'demo',
  language = 'en',
}: {
  url?: string;
  irModule?: string;
  token?: string;
  language?: string;
}) {
  return `${url}/component/${irModule}?token=${token}&lang=${language}`;
}
