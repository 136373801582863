import * as React from 'react';
import { Field } from '@oms/ui-field';
import { TextInput } from '@oms/ui-text-input';
import { createForm } from './createForm';
import { GRAPH_IMAGE } from 'constants/modules';

const [GraphImage] = createForm(GRAPH_IMAGE.key, () => (
  <>
    <Field as={TextInput} name="compare" label="Compare" />
  </>
));

export type GraphImageFormValues = {
  compare: string;
};

export { GraphImage };
