import React, { useCallback, useMemo } from 'react';
import { FormProps } from 'react-final-form';
import { FORM_ERROR } from 'final-form';
import produce from 'immer';
import { useToast } from '@oms/ui-toast';
import { Notification } from '@oms/ui-notification';
import client from 'client/customers';

const submit = () => void {};

/**
 * Generic settings form submit handler
 * Make sure your field names match up with the backend field property names.
 */
const useHandleSubmit = ({ customerId, irModule }: UseHandleSubmitOptions) => {
  const { data: customer } = client.useCustomer(customerId);
  const [mutate] = client.useUpdateCustomer(customerId);
  const toast = useToast();
  const submit: FormProps['onSubmit'] = useCallback(
    (values, form, onErrorCallback) => {
      const i = getIndex(customer?.products, irModule);
      const props = customer?.products?.[i]?.properties;
      // Create a payload
      const payload = produce(customer, draft => {
        // For each of the form fields ...
        Object.entries(values).forEach(([property, value]) => {
          // Find the corresponding index in props
          const j = getIndex(props, property);
          // if no properties exist assign an empty array
          if (!(draft as any).products[i]?.properties) {
            (draft as any).products[i].properties = [];
          }
          // if no match just push it in the newly created array
          if (j === -1) {
            (draft as any).products[i].properties.push({
              key: property,
              value,
            });
          } else {
            // Then mutate the original customer object with Immer.
            // We only need to assign a new value. This way we keep the original key and id unchanged
            (draft as any).products[i].properties[j].value = value;
          }
        });
      });

      if (payload) {
        const promise = mutate(payload, {
          onSuccess: () => {
            form.initialize(values);
            toast(
              toastProps => (
                <Notification
                  title="Changes saved"
                  status="success"
                  {...toastProps}
                >
                  Changes have been saved successfully.
                </Notification>
              ),
              {
                position: 'bottom-left',
                duration: 5000,
              },
            );
          },

          onError: async (error: any) => {
            // Pass the error to final form
            onErrorCallback?.({
              [FORM_ERROR]: error?.statusText || 'Error',
            });
            toast(
              toastProps => (
                <Notification title="Oops" status="error" {...toastProps}>
                  Something went wrong. Please try again.
                </Notification>
              ),
              {
                position: 'bottom-left',
                duration: 5000,
              },
            );
          },
        });
        return promise;
      }
    },
    [customer, mutate, irModule, toast],
  );
  return submit;
};

const useInitialValues = ({ customerId, irModule }: UseHandleSubmitOptions) => {
  const { data: customer } = client.useCustomer(customerId);
  return useMemo(() => {
    let initialValues: Record<string, any> = {};

    const p = customer?.products?.find(product => product.key === irModule)
      ?.properties;
    if (p) {
      p.forEach(property => {
        initialValues[property.key] = property.value;
      });
    }

    return initialValues;
  }, [customer, irModule]);
};

function getIndex(arr: any[] | undefined, property: string) {
  const index = arr
    ? ((arr?.findIndex(p => p.key === property) as unknown) as number)
    : -1;
  return index;
}

export type UseHandleSubmitOptions = {
  customerId: number | string;
  irModule: string;
};

export { submit, useHandleSubmit, useInitialValues };
