import React from 'react';
import { I18nProvider as LinguiProvider } from '@lingui/react';

import { Catalogs } from '@lingui/core';

let catalogs: Catalogs | undefined = undefined;

function I18nProvider({ language, ...props }: I18nProviderProps) {
  if (!catalogs?.[language]) {
    const promise = Promise.all([
      import(`locales/${language}/messages`),
      import(`@oms/components-i18n/locale/${language}/messages`),
    ]).then(([localCatalog, omsCatalog]) => {
      const config = {
        ...catalogs,
        [language]: {
          ...omsCatalog,
          ...localCatalog,
          messages: {
            ...omsCatalog.messages,
            ...localCatalog.messages,
          },
        },
      };
      catalogs = config;
    });
    throw promise;
  }

  return (
    <LinguiProvider
      language={language}
      missing="🚨"
      catalogs={catalogs}
      {...props}
    />
  );
}

type I18nProviderProps = {
  language: string;
  children: React.ReactNode;
};

export { I18nProvider };
