import React, { useCallback } from 'react';
import { Suggest } from '@oms/components-suggest';
import { FORM_ERROR } from 'final-form';
import { Field, Fieldset } from '@oms/ui-field';
import { TextInput } from '@oms/ui-text-input';
import { NumberInput } from '@oms/ui-number-input';
import { Checkbox, CheckboxGroup } from '@oms/ui-checkbox';
import { Box } from '@oms/ui-box';
import { SettingsForm as Form } from 'components/SettingsForm';
import client from 'client/customers';

import { FormProps } from 'react-final-form';

function CustomerSettings({ customerId }: any) {
  const { data: { products, ...settings } = {} } = client.useCustomer(
    customerId,
  );

  const [mutate] = client.useUpdateCustomer(customerId);

  const submit: FormProps['onSubmit'] = useCallback(
    (values, form, onErrorCallback) => {
      return mutate(
        snapshot => {
          const payload = { ...snapshot, ...values };
          return payload;
        },
        {
          onSuccess: () => {
            form.initialize(values);
          },
          onError: async (error: any) => {
            onErrorCallback?.({
              [FORM_ERROR]: error?.statusText || 'Error',
            });
          },
        },
      );
    },
    [mutate],
  );

  return (
    <Form initialValues={settings} onSubmit={submit} headerText="Settings">
      <Field as={TextInput} name="name" label="Name" />
      <Field
        as={TextInput}
        name="hubspot"
        label="HubSpot ID"
        leftElement={<Box as="img" src="/hs.svg" width="1rem" mx={2} />}
      />
      <Field
        as={TextInput}
        name="key"
        label="Key/token"
        message="This will be the key in the final URLs, and must match the filestructure to enable extensions and deprecated pages."
      />
      <Field as={TextInput} name="exchange" label="Exchange" />
      <Field as={Suggest} name="ticker" label="Ticker" />
      <Field
        as={NumberInput}
        name="delay"
        label="Delay"
        message="Delay in minutes"
      />
      <Field as={TextInput} name="notingdate" label="Noting date" />
      <Field as={TextInput} name="ipSubnet1" label="IP Subnet 1" />
      <Field as={TextInput} name="ipSubnet2" label="IP Subnet 2" />
      <Fieldset
        as={CheckboxGroup}
        name="language"
        legend="Languages"
        message="A description for this checkbox group"
      >
        <Field as={Checkbox} label="English" value="en" />
        <Field as={Checkbox} label="Norwegian" value="no" />
        <Field as={Checkbox} label="Swedish" value="se" />
      </Fieldset>
      <Fieldset
        as={CheckboxGroup}
        name="feed"
        legend="Feed"
        message="A description for this checkbox group"
      >
        <Field as={Checkbox} value="html" label="HTML" />
        <Field as={Checkbox} value="xml" label="XML" />
        <Field as={Checkbox} value="json" label="JSON" />
      </Fieldset>
    </Form>
  );
}

export interface CustomerSettingsProps {}

export { CustomerSettings };
