import getPeerGroup from 'utils/getPeerGroup';
import getFieldList from 'utils/getFieldList';
import { get, post, put, del } from './fetch';
import { CUSTOMERS_URL, SECURE_CUSTOMERS_URL } from './constants';
import { Product, ProductId } from './products-utils';

export type CustomerId = number | string;

/** The customer's key. */
export type CustomerKey = string;

/** The customer's theme properties. */
export type ThemeProperty = {
  id: number | string;
  key: string;
  value: string;
};

/** The customer properties to be updated. */
export type CustomerProperties = {
  /** The customer's id. */
  id: CustomerId;
  /** The customer's key. */
  key?: CustomerKey;
  /** The customer's name. */
  name?: string;
  /** The customer's ticker. */
  ticker?: string;
  /** The customer's exchange. */
  exchange?: string;
  /** The customer's sector. */
  sector?: string;
  /** The customer's organization number. */
  orgnr?: string;
  /** True if the customer's instrument is of type bonds, else false. */
  bonds?: boolean;
  /** The customer's data delay from realtime in minutes. */
  delay?: number;
  /** The customer's noting date. */
  notingdate?: Date | string;
  /** The customer's expiry date. */
  expirydate?: Date;
  /** The customer's products. */
  products?: Product[];
  properties: ThemeProperty[];
  hubspotId?: string;
};

export type AdditionCustomerProperties = {
  customerKey: string;
  token: string;
  itemSector: string;
  peerGroup: Record<'source' | 'item' | 'type', string>[];
  fieldList: string;
};

type QueryKey = string;

/** Returns a customer with products and properties. */
export const getCustomer = async (
  _queryKey: QueryKey,
  params: { customerId: CustomerId },
) => {
  const data: CustomerProperties = await get(
    `${SECURE_CUSTOMERS_URL}/${params.customerId}`,
  );
  return data;
};

/** Updates a customer with products and properties. */
export const updateCustomer = async (
  customerId: CustomerId,
  customerProperties: CustomerProperties,
) => {
  const response = await put(
    `${SECURE_CUSTOMERS_URL}/${customerId}`,
    JSON.stringify({ ...customerProperties, id: customerId }),
  );
  if (!response.ok) {
    throw new Error(response.statusText);
  }
};

/** Deletes a customer. */
export const deleteCustomer = async (customerId: CustomerId) => {
  await del(`${SECURE_CUSTOMERS_URL}/${customerId}`);
};

/** Creates a customer with products and properties. Returns the customer's id. */
export const createCustomer = async (
  customerProperties: Partial<CustomerProperties>,
) => {
  const response = await post(
    SECURE_CUSTOMERS_URL,
    JSON.stringify(customerProperties),
  );
  if (!response.ok) {
    throw new Error(response.statusText);
  }
  return (await response.json()) as { id: CustomerId };
};

/** Returns all customers. */
export const getAllCustomers = async (): Promise<CustomerProperties[]> => {
  const data = await get(SECURE_CUSTOMERS_URL);
  return data;
};

/** Returns a customer with products and properties. */
export const getCustomerByKey = async (
  _queryKey: QueryKey,
  params: {
    customerKey: CustomerKey;
  },
) => {
  const data: CustomerProperties = await get(
    `${CUSTOMERS_URL}/${params.customerKey}`,
  );

  const customer = {
    // this doesn't work for Bonds
    ...data,
    customerKey: params.customerKey,
    token: params.customerKey,
    itemSector: `${data.ticker}.${data.exchange}`,
    peerGroup: getPeerGroup(data) as AdditionCustomerProperties['peerGroup'],
    fieldList: getFieldList(data) as AdditionCustomerProperties['fieldList'],
  };

  return customer;
};

/** Returns all the items of the bonds issued by the customer if any. */
export const getBondItemsByCustomerKey = async (
  queryKey: QueryKey,
  params: { customerKey: CustomerKey },
) => {
  const data: CustomerProperties = await get(
    `${CUSTOMERS_URL}/${params.customerKey}/bond-items`,
  );
  return data;
};

/** Returns all the properties on a product on a customer. */
export const getCustomerProductProperties = async (
  queryKey: QueryKey,
  params: { customerId: CustomerId; productId: ProductId },
) => {
  const data: CustomerProperties = await get(
    `${CUSTOMERS_URL}/${params.customerId}/products/${params.productId}/properties`,
  );
  return data;
};
