import React, { useMemo } from 'react';
import Jaws from '@oms/jaws';
import {
  OMSContext,
  defaultConfig as defaultContextValues,
} from '@oms/components-context';
import { forwardRefWithAs } from '@oms/ui-utils';
import { defaultContextValue as defaultComponents } from '@oms/ui-components-context';
import { useModuleTheme } from 'components/ModuleTheme';
import { BASE_SECURE_URL } from 'constants/Jaws';

interface LinkProps {
  /** string | function | object */
  to: any;
  children: React.ReactNode;
  [otherProps: string]: any;
}

const Link = forwardRefWithAs<LinkProps, 'a'>(({ to, ...props }, ref) => (
  // eslint-disable-next-line
  <a ref={ref} href={to} target="_top" {...props} />
));

const components = {
  ...defaultComponents,
  Link,
};

function OMSProvider({ children, token, lang }: OMSProviderProps) {
  const config = useConfig(token);
  const jaws = useJawsInstance(token, lang);
  const { theme, GlobalStyles } = useModuleTheme(token);
  return (
    <OMSContext
      config={config}
      jaws={jaws}
      language={lang}
      theme={theme as any}
      global={<GlobalStyles />}
      components={components}
    >
      {children}
    </OMSContext>
  );
}

function useJawsInstance(token: string, lang: string) {
  return useMemo(() => {
    const instance = new Jaws({
      url: BASE_SECURE_URL,
      useWebSockets: false,
      query: {
        auth: `key=${token}`,
        lang: lang,
      },
    });
    return { instance };
  }, [token, lang]);
}

function useConfig(token: string) {
  return useMemo(
    () => ({
      ...defaultContextValues,
      baseUrl: `${defaultContextValues.baseUrl}/secure`,
      /* Domain should NOT be secure */
      // eslint-disable-next-line no-restricted-globals
      domainUrl: `//${location.host}/server/domain/{?query*}`,
      graphdataUrl: appendToken(defaultContextValues.graphdataUrl, token),
      transactionsUrl: appendToken(defaultContextValues.transactionsUrl, token),
      jawsUrl: appendToken(defaultContextValues.jawsUrl, token),
    }),
    [token],
  );
}

function appendToken(url: string, token: string) {
  return `${url}&auth=key%3D${token}`;
}
interface OMSProviderProps {
  children: React.ReactNode;
  token: string;
  lang: string;
}

export { OMSProvider };
