import React, { createContext, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery, useMutation, queryCache } from 'react-query';
import { getSearchParams } from 'utils/useSearchParams';
import {
  getCustomer,
  createCustomer,
  updateCustomer,
  deleteCustomer,
  getAllCustomers,
  getCustomerByKey,
  getBondItemsByCustomerKey,
  getCustomerProductProperties,
  CustomerId,
  CustomerKey,
  CustomerProperties,
  AdditionCustomerProperties,
} from './customers-utils';
import { ProductId } from './products-utils';
import getPeerGroup from 'utils/getPeerGroup';
import getFieldList from 'utils/getFieldList';

const ONE_DAY = 60 * 60 * 24;

export function useCustomer(customerId: CustomerId) {
  return useQuery(['customer', { customerId }], getCustomer, {
    suspense: true,
    staleTime: ONE_DAY,
  });
}

export function useCreateCustomer() {
  return useMutation(createCustomer, {
    onSuccess: ({ id: customerId }) => {
      queryCache.refetchQueries('customers');
      queryCache.prefetchQuery(['customer', { customerId }], getCustomer);
    },
  });
}

type ObjectState = Partial<CustomerProperties>;
type FunctionState = (
  previousState: Partial<CustomerProperties>,
) => Partial<CustomerProperties>;
type Update = ObjectState | FunctionState;

export function useUpdateCustomer(customerId: CustomerId) {
  // read customer data from cache
  const data: Partial<CustomerProperties> =
    queryCache.getQueryData(['customer', { customerId }]) || {};

  return useMutation(
    (update: Update) => {
      return updateCustomer(customerId, {
        ...data,
        ...(typeof update === 'function' ? update(data) : update),
      } as CustomerProperties);
    },
    {
      onSettled: () => {
        queryCache.refetchQueries('customers');
        queryCache.refetchQueries(['customer', { customerId }]);
      },
    },
  );
}

export function useDeleteCustomer() {
  return useMutation(deleteCustomer, {
    onSuccess: () => {
      queryCache.refetchQueries('customers');
    },
  });
}

export function useAllCustomers(config?: { suspense: boolean }) {
  return useQuery('customers', () => getAllCustomers(), {
    ...config,
    staleTime: ONE_DAY,
  });
}

/////////////////////////////////////////

export function useGetCustomerByToken(customerKey?: CustomerKey) {
  return useQuery(['customer-by-key', { customerKey }], getCustomerByKey, {
    enabled: customerKey,
    suspense: true,
  });
}

type CustomerContextType = CustomerProperties & AdditionCustomerProperties;
type CustomerProviderProps = {
  children: React.ReactNode;
  token: string;
};

const params = getSearchParams<{ token: string }>(window?.location);

const initialContext = queryCache.getQueryData([
  'customer-by-key',
  { customerKey: params.token },
]) as CustomerContextType;

const CustomerContext = createContext(initialContext);

export const useCustomerByToken = (_token?: string) =>
  useContext(CustomerContext);

export function CustomerProvider({ children, token }: CustomerProviderProps) {
  const { customerId } = useParams<any>();

  const { data: themeData } = useQuery(
    ['theme', { customerId }],
    () => {
      return getCustomer('', { customerId }).then((data: any) => {
        const customer = {
          // this doesn't work for Bonds
          ...data,
          customerKey: data.customerKey,
          token: data.customerKey,
          itemSector: `${data.ticker}.${data.exchange}`,
          peerGroup: getPeerGroup(
            data,
          ) as AdditionCustomerProperties['peerGroup'],
          fieldList: getFieldList(
            data,
          ) as AdditionCustomerProperties['fieldList'],
        };

        return customer;
      });
    },
    {
      suspense: true,
      enabled: customerId,
    },
  );

  const { data } = useGetCustomerByToken(token);

  if (customerId) {
    if (!themeData) return null;
    return (
      <CustomerContext.Provider
        value={(themeData as unknown) as CustomerContextType}
      >
        {children}
      </CustomerContext.Provider>
    );
  } else {
    if (!data) return null;
    return (
      <CustomerContext.Provider value={data}>
        {children}
      </CustomerContext.Provider>
    );
  }
}

/////////////////////////////////////////////////

export function useBondItemsByToken(customerKey: CustomerKey) {
  return useQuery(
    ['bond-items-by-customer-key', { customerKey }],
    getBondItemsByCustomerKey,
  );
}

export function useCustomerProductProperties(
  customerId: CustomerId,
  productId: ProductId,
) {
  return useQuery(
    ['customer-product-properties', { customerId, productId }],
    getCustomerProductProperties,
  );
}

/**
 * Fetch while you render
 * @see https://github.com/tannerlinsley/react-query#fetch-on-render-vs-fetch-as-you-render
 */

export async function prefetchCustomer(customerId: CustomerId) {
  await queryCache.prefetchQuery(['customer', { customerId }], getCustomer);
}

export async function prefetchAllCustomers() {
  await queryCache.prefetchQuery(['customers'], getAllCustomers);
}

export async function prefetchCustomerByToken(customerKey: CustomerKey) {
  await queryCache.prefetchQuery(
    ['customer-by-key', { customerKey }],
    getCustomerByKey,
  );
}
export async function prefetchBondItemsByToken(customerKey: CustomerKey) {
  await queryCache.prefetchQuery(
    ['bond-items-by-customer-key', { customerKey }],
    getBondItemsByCustomerKey,
  );
}

export async function prefetchCustomerProductProperties(
  customerId: CustomerId,
  productId: ProductId,
) {
  await queryCache.prefetchQuery(
    ['customer-product-properties', { customerId, productId }],
    getCustomerProductProperties,
  );
}

const exports = {
  useCustomer,
  useCreateCustomer,
  useUpdateCustomer,
  useDeleteCustomer,
  useGetCustomerByToken,
  useCustomerByToken,
  useBondItemsByToken,
  useCustomerProductProperties,
  useAllCustomers,
  prefetchCustomer,
  prefetchCustomerByToken,
  prefetchBondItemsByToken,
  prefetchCustomerProductProperties,
  CustomerProvider,
};

export default exports;
