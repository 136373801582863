import React from 'react';
import { Box } from '@oms/ui-box';
import { Typography } from './Typography';
import { Table } from './Table';
import { Button } from './Button';
import { Chart } from './Chart';
import { MarketTrends } from './MarketTrends';
import { Page } from './Page';

function Styles(_props: any) {
  return (
    <Box mx={-4} mt={-4}>
      <Page />
      <Typography />
      <Table />
      <Button />
      <Chart />
      <MarketTrends />
    </Box>
  );
}

type Border = 'none' | 'bottom' | 'both' | 'all';
export type FontStyle = {
  variant?: string;
  label?: string | number;
  value?: string | number;
};
type LetterCase = 'none' | 'uppercase';
type Striping = 'none' | 'solid' | 'striped';
type BorderRadius = 'none' | 'sm' | 'md' | 'rounded';
type ChartFill = 'none' | 'solid' | 'gradient';
export interface FormValues {
  // HEADING
  headingFontFamily: string;
  headingFontStyle: FontStyle;
  headingTextColor: string;
  // BODY
  bodyFontFamily: string;
  bodyFontStyle: FontStyle;
  bodyTextColor: string;
  linkTextColor: string;
  //
  /**  @deprecated */
  tableHeadingFontFamily?: string;
  /**  @deprecated */
  tableHeadingFontStyle?: FontStyle;
  //
  tableHeadTextColor: string;
  tableHeadBackgroundColor: string;
  tableHeadLetterCase: LetterCase;
  tableHeadBorder: Border;
  tableHeadBorderColor: string;
  // TABLE CELL
  /** @deprecated */
  tableCellTextColor: string;
  tableCellBorder: Border;
  tableCellBorderColor: string;
  tableCellBackground: Striping;
  tableCellBackgroundColor: string;
  tableCellBackgroundColorOdd: string;
  // BUTTON
  buttonBackgroundColor: string;
  buttonTextColor: string;
  buttonBorderColor: string;
  buttonBorderRadius: BorderRadius;
  // CHART
  chartLineColor: string;
  chartFill: ChartFill;
  chartFillTopColor: string;
  chartFillBottomColor: string;
  // TREND
  posColor: string;
  negColor: string;
  // Page
  pageBackgroundColor?: string;
}

export interface ApiValues
  extends Omit<FormValues, 'bodyFontStyle' | 'headingFontStyle'> {
  headingFontVariant: string;
  headingFontWeight: string | number;
  bodyFontVariant: string;
  bodyFontWeight: string | number;
}

export { Styles };
