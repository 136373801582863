import React from 'react';
import { Box } from '@oms/ui-box';
import { Stack } from '@oms/ui-stack';
import { Skeleton } from '@oms/ui-skeleton';

function ChartSkeleton() {
  return (
    <Stack gap={4}>
      <Box borderWidth="sm" height="16rem" />
      <Skeleton height="4rem" />
    </Stack>
  );
}

export { ChartSkeleton };
