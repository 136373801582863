import memoize from 'memoize-one';

const typeMap = {
  '0': 'equity',
  // TODO WHAT?
  '!': 'index',
  '1': 'index',
  '2': 'commodity',
  '3': 'currency',
};

export const getPeerGroup = customerData => {
  if (!customerData) return null;

  const peerGroup = customerData?.products?.find(
    product => product.key === 'peerGroupBenchmark',
  );

  if (!peerGroup) return null;

  const instruments = peerGroup?.properties?.find(
    property => property.key === 'instruments',
  );

  if (!instruments) return null;

  return instruments?.value
    ?.split(',')
    ?.filter(item => item)
    ?.map(instrument => {
      const [source, item, type, columns] = instrument.split(';');
      return { source, item, type: typeMap[type], columns };
    });
};

export default memoize(getPeerGroup);
