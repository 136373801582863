import React from 'react';
import { Skeleton, SkeletonTable } from '@oms/ui-skeleton';
import { ModuleSection, ModuleHeading } from 'components/Module';

export default function OrderDepthSkeleton(props: any) {
  return (
    <ModuleSection {...props}>
      <ModuleHeading>
        <Skeleton height="1rem" width="10rem" />
      </ModuleHeading>
      <SkeletonTable columns={5} rows={5} mt={3} />
    </ModuleSection>
  );
}

export { OrderDepthSkeleton };
