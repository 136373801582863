import { get, getRaw, post } from './fetch';
import { AUTHENTICATED, AUTH_URL, LOGOUT_URL } from './constants';

type Credentials = { username: string; password: string };

type Roles = 'PRODUCT_ADMIN' | 'CUSTOMER_ADMIN';

export type UserShape = {
  authenticated: boolean;
  remembered: boolean;
  principals: Array<{
    sub: string;
    iss?: string;
    roles?: Roles[];
    [key: string]: any;
  }>;
  [key: string]: any;
};

/** Returns information about the current user. */
export const getUser = async () => {
  const data: UserShape = await get(AUTHENTICATED);
  return data;
};

/** Returns information about the current user. */
export const getUserWithoutRedirect = async (): Promise<UserShape> => {
  const response = await getRaw(AUTHENTICATED);
  if (!response.ok) {
    throw response;
  }
  return await response.json();
};

/** Sets an omstoken cookie representing the entity identified by the credentials. */
export const authenticateUser = async (credentials: Credentials) => {
  const response = await post(AUTH_URL, JSON.stringify(credentials));
  if (!response.ok) throw response;
};

/** Sets an omstoken cookie with the value "deleted" resulting in logout. */
export const logoutUser = async () => {
  const response = await post(LOGOUT_URL);
  if (!response.ok) throw response;
};
