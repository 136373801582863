import { useQuery, useMutation, queryCache } from 'react-query';
import { useHistory } from 'react-router-dom';
import {
  getUser,
  getUserWithoutRedirect,
  authenticateUser,
  logoutUser,
  UserShape,
} from './auth-utils';

export const prefetchUser = () =>
  queryCache.prefetchQuery('auth', getUserWithoutRedirect);

export function useAuth() {
  return useQuery('auth', getUser);
}

export function useAuthenticate() {
  const history = useHistory();
  return useMutation(authenticateUser, {
    onSuccess: (..._args) => {
      queryCache.refetchQueries('auth');
      history.push('/admin/customers');
    },
  });
}

export function useLogout() {
  const history = useHistory();
  return useMutation(logoutUser, {
    onSuccess: () => {
      queryCache.refetchQueries('auth');
      history.push('/admin/login');
    },
  });
}

export const getUsername = (user: UserShape) => {
  if (user && 'principals' in user) {
    const name = user.principals[0]?.sub;
    return name;
  }
};

const exports = { useAuth, useAuthenticate, useLogout, getUsername };
export default exports;
