import React, { useState } from 'react';
import { Field, RenderField } from '@oms/ui-field';
import { useField } from 'react-final-form';
import { Heading } from '@oms/ui-heading';
import { Switch } from '@oms/ui-switch';
import { DisclosureSection } from 'components/DisclosureSection';
import { FontField } from './FontField';
import { ColorInput } from './ColorInput';
import { validateColorInput } from './utils';
import { defaultTheme } from 'components/ModuleTheme';

// TODO Add font variant/weight settings.
// +++++++++++++++++++++++++++++++++++++
// import { Select } from '@oms/ui-select';
// import { OnChange } from './OnChange';
// import { getVariants } from './utils';

function Typography() {
  const headingFontFamily = useField('headingFontFamily');
  const bodyFontFamily = useField('bodyFontFamily');

  const [heading, setHeading] = useState(() => !!headingFontFamily.input.value);
  const [body, setBody] = useState(() => !!bodyFontFamily.input.value);

  const onChangeHeading = () => {
    setHeading(s => {
      const next = !s;
      const notCustom = !next;
      if (notCustom) {
        headingFontFamily.input.onChange(defaultTheme.headingFontFamily);
      }
      return next;
    });
  };
  const onChangeBody = () => {
    setBody(s => {
      const next = !s;
      const notCustom = !next;
      if (notCustom) {
        bodyFontFamily.input.onChange(defaultTheme.bodyFontFamily);
      }
      return next;
    });
  };

  return (
    <DisclosureSection title="Typography" initialOpen={true}>
      <Heading as="h4" variant="heading6">
        Heading
      </Heading>
      <RenderField
        as={Switch}
        name="customHeading"
        label="Custom font"
        checked={heading}
        onChange={onChangeHeading}
      />
      {heading && (
        <>
          <FontField name="headingFontFamily" />
          {/*<OnChange name="headingFontFamily">
            {(value: string) =>
              value ? (
                <Field
                  as={Select}
                  name="headingFontStyle"
                  label="Style"
                  items={value ? getVariants(value) : []}
                  groupByKey="variant"
                  disabled={!value}
                />
              ) : null
            }
          </OnChange>*/}
        </>
      )}
      <Field
        as={ColorInput}
        name="headingTextColor"
        label="Text color"
        validate={validateColorInput}
      />
      <Heading as="h4" variant="heading6">
        Body
      </Heading>
      <RenderField
        as={Switch}
        name="customBody"
        label="Custom font"
        checked={body}
        onChange={onChangeBody}
      />
      {body && (
        <>
          <FontField name="bodyFontFamily" />
          {/*<OnChange name="bodyFontFamily">
            {(value: string) =>
              value ? (
                <Field
                  as={Select}
                  name="bodyFontStyle"
                  label="Style"
                  items={value ? getVariants(value) : []}
                  groupByKey="variant"
                  disabled={!value}
                />
              ) : null
            }
          </OnChange>*/}
        </>
      )}

      <Field
        as={ColorInput}
        name="bodyTextColor"
        label="Text color"
        validate={validateColorInput}
      />
      <Field
        as={ColorInput}
        name="linkTextColor"
        label="Link color"
        validate={validateColorInput}
      />
    </DisclosureSection>
  );
}

export { Typography };
