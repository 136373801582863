import * as React from 'react';
import { Field } from '@oms/ui-field';
import { TextInput } from '@oms/ui-text-input';
import { createForm } from './createForm';
import { COMPANY_INFO } from 'constants/modules';

const [CompanyInfo] = createForm(COMPANY_INFO.key, () => (
  <>
    <Field as={TextInput} name="prelude_en" label="Prelude English" />
    <Field as={TextInput} name="prelude_no" label="Prelude Norwegian" />
    <Field as={TextInput} name="postlude_en" label="Postlude English" />
    <Field as={TextInput} name="postlude_no" label="Postlude Norwegian" />
  </>
));

export { CompanyInfo };
