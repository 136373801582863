import React from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import moment from 'moment-timezone';
import locale from 'moment/locale/nb';
import { SharedProvider } from 'components/SharedProvider';
import runHeightChangeWatcher from 'utils/runHeightChangeWatcher';
import { Route } from 'components/Route';

import { AdminRoutes } from './admin-routes';
import { ModuleRoutes } from './module-routes';
import { AdminProvider as AppWrapper } from 'components/AdminProvider';

const NotFoundPage = React.lazy(() => import('pages/NotFoundPage'));
const PublicView = React.lazy(() => import('pages/PublicView'));
const LandingPage = React.lazy(() => import('pages/LandingPage'));

// Load norwegian locale
moment.updateLocale('nb', locale);
moment.locale('nb');
// Force moment object to be created in the oslo tz
moment.tz.setDefault('Europe/Oslo');
runHeightChangeWatcher();

export default function Main() {
  return (
    <SharedProvider>
      <Router>
        <Switch>
          <Route
            path="/public/:token"
            element={
              <AppWrapper>
                <PublicView />
              </AppWrapper>
            }
          />
          <Route path="/component/:irModule" element={<ModuleRoutes />} />
          <Route path="/admin" element={<AdminRoutes />} />
          <Route path="/" exact element={<LandingPage />} />
          <Route path="*" element={<NotFoundPage />} />
        </Switch>
      </Router>
    </SharedProvider>
  );
}
