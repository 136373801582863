import React from 'react';
import {
  useDisclosure,
  DisclosureButton,
  DisclosureContent,
} from '@oms/ui-disclosure';
import { Clickable } from '@oms/ui-clickable';
import { InfoBubble } from '@oms/ui-info-bubble';
import { Box } from '@oms/ui-box';
import { Icon, light } from '@oms/ui-icon';
import { Text } from '@oms/ui-text';
import { Stack, StackProps } from '@oms/ui-stack';

function DisclosureSection({
  title,
  info,
  children,
  initialOpen,
}: DisclosureSectionProps) {
  const disclosure = useDisclosure({ visible: initialOpen });
  return (
    <Box borderTop="sm" borderColor="border">
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        pr={4}
      >
        <DisclosureButton {...disclosure} as={Clickable} px={4} py={4} flex={1}>
          <Box
            as="span"
            center
            display="inline-fex"
            bg="surface-2"
            borderRadius="md"
          >
            <Icon
              icon={light.faAngleRight}
              rotate={disclosure.visible ? 90 : 0}
            />
          </Box>
          <Text as="span" ml={4}>
            {title}
          </Text>
        </DisclosureButton>
        {info ? <InfoBubble aria-label="info">{info}</InfoBubble> : null}
      </Box>

      <DisclosureContent {...disclosure}>
        <Stack gap={4} pb={4} px={4}>
          {children}
        </Stack>
      </DisclosureContent>
    </Box>
  );
}

type DisclosureSectionProps = {
  title: string;
  children: StackProps['children'];
  info?: string | TemplateStringsArray;
  initialOpen?: boolean;
};

export { DisclosureSection };
