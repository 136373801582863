import * as React from 'react';
import { Field } from '@oms/ui-field';
import { TextInput } from '@oms/ui-text-input';
import { createForm } from './createForm';
import { GENERIC_SNAPSHOTS } from 'constants/modules';

const [GenericSnapshots] = createForm(GENERIC_SNAPSHOTS.key, () => (
  <>
    <Field as={TextInput} name="items" label="Items" />
  </>
));

export type GenericSnapshotsFormValues = {
  items: string;
};

export { GenericSnapshots };
