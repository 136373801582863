import React from 'react';
import { Skeleton, SkeletonTable } from '@oms/ui-skeleton';
import { ModuleSection, ModuleHeading, ModuleProps } from 'components/Module';

export default function TradesSkeleton({ ...styleProps }: ModuleProps<any>) {
  return (
    <ModuleSection {...styleProps}>
      <ModuleHeading>
        <Skeleton height="1rem" width="10rem" />
      </ModuleHeading>
      <SkeletonTable columns={3} rows={10} />
    </ModuleSection>
  );
}

export { TradesSkeleton };
