import React from 'react';
import { Link } from 'react-router-dom';
import { useField } from 'react-final-form';
import { Helmet } from 'react-helmet';
import { Global, css } from '@xstyled/emotion';
import { UIProvider } from '@oms/ui-provider';
import { GlobalStyles } from '@oms/ui-theming';
import { theme } from 'theme';
interface SharedProviderProps {
  token: string;
  children: React.ReactNode;
}

const components = {
  useField,
  Link,
};

// SharedProviders
function SharedProvider({ children }: SharedProviderProps) {
  return (
    <React.Suspense fallback={null}>
      <UIProvider
        global={<Globals />}
        components={components}
        theme={{ ...theme, useCustomProperties: false }}
      >
        {children}
      </UIProvider>
    </React.Suspense>
  );
}

function Globals() {
  return (
    <>
      <Helmet>
        <link
          href="https://fonts.googleapis.com/css2?family=Roboto&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <Global
        styles={css`
          body {
            margin: 0;
          }
        `}
      />
      <GlobalStyles
        fontFamily={`'Roboto', -apple-system, BlinkMacSystemFont,
'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans',
'Helvetica Neue', sans-serif`}
      />
    </>
  );
}

export { SharedProvider };
