import React from 'react';
import { Field } from '@oms/ui-field';
import { DisclosureSection } from 'components/DisclosureSection';
import { ColorInput } from './ColorInput';
import { validateColorInput } from './utils';

function Page() {
  return (
    <DisclosureSection title="Page" initialOpen={true}>
      <Field
        as={ColorInput}
        name="pageBackgroundColor"
        label="Page background color"
        validate={validateColorInput}
      />
      <Field
        as={ColorInput}
        name="borderColor"
        label="Border color"
        validate={validateColorInput}
      />
    </DisclosureSection>
  );
}

export { Page };
