import * as React from 'react';
import { Field } from '@oms/ui-field';
import { TextInput } from '@oms/ui-text-input';
import { createForm } from './createForm';
import { PROFIT_CALCULATOR } from 'constants/modules';

const [ProfitCalculator] = createForm(PROFIT_CALCULATOR.key, () => (
  <>
    <Field as={TextInput} name="historyStartDate" label="History start date " />
  </>
));

export type ProfitCalculatorFormValues = {
  historyStartDate: string;
};

export { ProfitCalculator };
