import memoize from 'memoize-one';

const defaultFieldList = 'W,M,3M,6M,1Y,2Y';
export const fieldMap = {
  W: 'CHANGE_WEEK_PCT',
  M: 'CHANGE_MONTH_PCT',
  Y: 'CHANGE_YEAR_PCT',
  '7D': 'CHANGE_7DAYS_PCT',
  '1M': 'CHANGE_1MONTH_PCT',
  '3M': 'CHANGE_3MONTHS_PCT',
  '6M': 'CHANGE_6MONTHS_PCT',
  '1Y': 'CHANGE_1YEAR_PCT',
  '2Y': 'CHANGE_2YEARS_PCT',
  '3Y': 'CHANGE_3YEARS_PCT',
  '4Y': 'CHANGE_4YEARS_PCT',
  '5Y': 'CHANGE_5YEARS_PCT',
};

const getColumns = fieldList => {
  const configurableFields = fieldList
    .split(',')
    .map(fieldKey => fieldMap[fieldKey])
    .join();

  return `ITEM,CURRENCY,${configurableFields},LAST`;
};

export const getFieldList = customerData => {
  if (!customerData) return null;

  const peerGroup = customerData.products?.find(
    product => product.key === 'peerGroupBenchmark',
  );

  if (!peerGroup) return null;

  const fieldList = peerGroup.properties?.find(
    property => property.key === 'fieldList',
  );

  return getColumns(fieldList ? fieldList.value : defaultFieldList);
};

export default memoize(getFieldList);
