import * as React from 'react';
import { Field } from '@oms/ui-field';
import { NumberInput } from '@oms/ui-number-input';
import { TextInput } from '@oms/ui-text-input';
import { SettingsForm as Form } from 'components/SettingsForm';
import { modules } from 'constants/modules';
import { useHandleSubmit, useInitialValues } from './utils';

import { FormProps } from './types';

function CompanyDisclosureArchive({
  standalone = true,
  customerId,
  ..._props
}: FormProps) {
  const submit = useHandleSubmit({
    customerId,
    irModule: modules.COMPANY_DISCLOSURES_ARCHIVE.key,
  });
  const initialValues = useInitialValues({
    customerId,
    irModule: modules.COMPANY_DISCLOSURES_ARCHIVE.key,
  });

  if (!standalone) return <Fields />;
  return (
    <Form onSubmit={submit} initialValues={initialValues}>
      <Fields />
    </Form>
  );
}

function Fields() {
  return (
    <>
      <Field as={NumberInput} name="pageSize" label="Page size" />
      <Field as={TextInput} name="languageFilter" label="Language filter" />
      <Field as={TextInput} name="sourceFilter" label="Source filter" />
    </>
  );
}

export { CompanyDisclosureArchive };
