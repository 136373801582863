import * as React from 'react';
import { Field, Fieldset } from '@oms/ui-field';
import { TextInput } from '@oms/ui-text-input';
import { NumberInput } from '@oms/ui-number-input';
import { Radio, RadioGroup } from '@oms/ui-radio';
import { Stack } from '@oms/ui-stack';
import { createForm } from './createForm';
import { DEMOGRAPHICS_ACCOUNT_TYPE } from 'constants/modules';

const [DemographicsAccountType] = createForm(
  DEMOGRAPHICS_ACCOUNT_TYPE.key,
  () => (
    <>
      <Fieldset as={RadioGroup} name="presentation" legend="Presentation">
        <Field as={Radio} label="Table" value="Table" />
        <Field as={Radio} label="Barchart" value="Barchart" />
        <Field as={Radio} label="Piechart" value="Piechart" />
      </Fieldset>
      <Field as={NumberInput} name="count" label="Count" />
      <Stack orientation="horizontal" gap={4}>
        <Field as={NumberInput} name="width" label="Width" flex={1} />
        <Field as={NumberInput} name="height" label="Height" flex={1} />
      </Stack>
      <Field as={TextInput} name="background" label="Background" />
      <Field as={TextInput} name="shadow" label="Shadow" />
      <Field as={TextInput} name="colors" label="Colors" />
      <Fieldset
        as={RadioGroup}
        name="investors"
        legend="Investors"
        orientation="horizontal"
      >
        <Field as={Radio} label="Yes" value={true} />
        <Field as={Radio} label="No" value={false} />
      </Fieldset>
      <Fieldset
        as={RadioGroup}
        name="legend"
        legend="Legend"
        orientation="horizontal"
      >
        <Field as={Radio} label="Yes" value={true} />
        <Field as={Radio} label="No" value={false} />
      </Fieldset>
    </>
  ),
);

export type DemographicsAccountTypeFormValues = {
  presentation: string;
  count: number;
  width: number;
  height: number;
  background: string;
  shadow: string;
  colors: string;
  investors: 'true' | 'false';
  legend: 'true' | 'false';
};

export { DemographicsAccountType };
