import * as React from 'react';
import { Field, Fieldset } from '@oms/ui-field';
import { TextInput } from '@oms/ui-text-input';
import { Radio, RadioGroup } from '@oms/ui-radio';
import { createForm } from './createForm';
import { PEER_GROUP_BENCHMARK } from 'constants/modules';

const [PeerGroupBenchmark] = createForm(PEER_GROUP_BENCHMARK.key, () => (
  <>
    <Field as={TextInput} name="instruments" label="Instruments" />
    <Fieldset
      as={RadioGroup}
      name="last"
      legend="Last"
      orientation="horizontal"
    >
      <Field as={Radio} label="Yes" value="true" />
      <Field as={Radio} label="No" value="false" />
    </Fieldset>
    <Field as={TextInput} name="fieldList" label="Field list" />
    <Fieldset
      as={RadioGroup}
      name="longnames"
      legend="Long names"
      orientation="horizontal"
    >
      <Field as={Radio} label="Yes" value="true" />
      <Field as={Radio} label="No" value="false" />
    </Fieldset>
  </>
));

export type PeerGroupBenchmarkFormValues = {
  instrument: string;
  last: 'true' | 'false';
  fieldList: string;
  longnames: 'true' | 'false';
};

export { PeerGroupBenchmark };
