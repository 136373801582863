import React from 'react';
import { Box } from '@oms/ui-box';
import { Skeleton } from '@oms/ui-skeleton';
import { ModuleSection, ModuleHeading } from 'components/Module';

export default function SnapshotSkeleton() {
  return (
    <ModuleSection>
      <Box display="flex" flexDirection="column">
        <Box display="flex" flex={1} justifyContent="space-between">
          <Box display="flex" flexDirection="column">
            <ModuleHeading>
              <Skeleton height="2rem" width="14rem" animated />
            </ModuleHeading>
            <Skeleton height="1rem" width="10rem" mt={2} animated />
          </Box>
          <Skeleton height="5rem" width="5rem" animated />
        </Box>
        <Skeleton minHeight="6rem" mt={3} animated />
      </Box>
    </ModuleSection>
  );
}

export { SnapshotSkeleton };
