import React from 'react';
import { Skeleton, SkeletonTable } from '@oms/ui-skeleton';
import { ModuleSection, ModuleHeading } from 'components/Module';

export default function PeerGroupSkeleton() {
  return (
    <ModuleSection>
      <ModuleHeading>
        <Skeleton height="1rem" width="10rem" />
      </ModuleHeading>
      <SkeletonTable rows={5} columns={3} />
    </ModuleSection>
  );
}

export { PeerGroupSkeleton };
