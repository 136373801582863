import React, { Suspense, lazy } from 'react';
import { ErrorBoundary } from '@oms/ui-error-boundary';
import { Stack } from '@oms/ui-stack';
import { Box } from '@oms/ui-box';
import { ModuleProps } from 'components/Module';
import { Disclaimer } from 'components/Disclaimer';

import { SnapshotSkeleton } from 'components/Snapshot/SnapshotSkeleton';
import { ChartSkeleton } from 'components/AnalysisChart/ChartSkeleton';
import { ProfitCalculatorSkeleton } from 'components/Calculator/ProfitCalculatorSkeleton';
import { OrderDepthSkeleton } from 'components/OrderDepth/OrderDepthSkeleton';
import { PeerGroupSkeleton } from 'components/PeerGroup/PeerGroupSkeleton';
import { CompanyInfoSkeleton } from 'components/CompanyInfo/CompanyInfoSkeleton';
import { TradesSkeleton } from 'components/Trades/TradesSkeleton';
// import { lang } from 'moment-timezone';

const Snapshot = lazy(() => import('components/Snapshot'));
const Chart = lazy(() => import('components/AnalysisChart'));
const Calculator = lazy(() => import('components/Calculator'));
const OrderDepth = lazy(() => import('components/OrderDepth'));
const PeerGroup = lazy(() => import('components/PeerGroup'));
const Trades = lazy(() => import('components/Trades'));
const CompanyInfo = lazy(() => import('components/CompanyInfo'));

export default function StandardPage({ token }: ModuleProps) {
  return (
    <Stack
      gap={10}
      p={4}
      maxWidth="80rem"
      minHeight="100%"
      width="100%"
      mx="auto"
    >
      <ErrorBoundary>
        <Suspense fallback={<SnapshotSkeleton />}>
          <Snapshot token={token} />
        </Suspense>
      </ErrorBoundary>
      <ErrorBoundary>
        <Suspense fallback={<ChartSkeleton />}>
          <Chart token={token} />
        </Suspense>
      </ErrorBoundary>
      <ErrorBoundary>
        <Suspense fallback={<ProfitCalculatorSkeleton token="" />}>
          <Calculator token={token} standalone={false} />
        </Suspense>
      </ErrorBoundary>
      <ErrorBoundary>
        <Suspense fallback={<PeerGroupSkeleton />}>
          <PeerGroup token={token} lang="en" standalone={false} />
        </Suspense>
      </ErrorBoundary>
      <Box display="flex" flexDirection={{ base: 'column', md: 'row' }}>
        <ErrorBoundary>
          <Suspense fallback={<TradesSkeleton />}>
            <Trades token={token} mr={{ md: 2 }} standalone={false} />
          </Suspense>
        </ErrorBoundary>
        <ErrorBoundary>
          <Suspense fallback={<OrderDepthSkeleton />}>
            <OrderDepth
              token={token}
              ml={{ md: 2 }}
              mt={{ base: 8, md: 0 }}
              standalone={false}
            />
          </Suspense>
        </ErrorBoundary>
      </Box>
      <Suspense fallback={<CompanyInfoSkeleton />}>
        <CompanyInfo token={token} standalone={false} />
      </Suspense>
      <Disclaimer />
    </Stack>
  );
}

export { StandardPage };
